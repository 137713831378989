import { FullStory } from '@fullstory/browser';
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import InteractionItem from "../common/InteractionItem";
import ListSummary from "../common/ListSummary";
import LoadingSkeleton from "../common/LoadingSkeleton";
import NoResultCard from "../common/NoResultCard";
import Notifications from "../common/Notifications";
import { appSlice } from "../store/appSlice";
import { getInteractions } from "../store/storeFunctions";
import { hasRedesignedHomepage } from "../utils/functions";
import Overview from "./overview/Overview";

const Interactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { user, filtersInteractions, procurementFilterData, interactions, isLoading, sortBy, sortDirection, page } =
    useSelector((state) => state.appSlice);

  const { setFiltersInteractions } = appSlice.actions;


  const selectedSort = `${sortBy}-${sortDirection}`;

  const team = searchParams.get('team');

  if (team) {
    dispatch(setFiltersInteractions({
      ...filtersInteractions,
      timeframe: 7,
      renewals: null,
      procurement_involved: null,
    }));

    navigate('/interactions');
  }

  const handleSortChange = (event) => {
    const value = event.target.value;
    const [sortBy, sortDirection] = value.split("-");

    dispatch(appSlice.actions.setSortBy(sortBy));
    dispatch(appSlice.actions.setSortDirection(sortDirection || 'desc'));
  };

  useEffect(() => {
    document.title = "PartnerElement | Interactions";

    if(!procurementFilterData?.internal_stakeholders  || !user)  return;

    dispatch(
      getInteractions({
        search_query: searchParams.get('q'),
        timeframe: filtersInteractions.timeframe,
        stage: filtersInteractions.engagementStage,
        procurement_involved: filtersInteractions.procurementInvolved,
        renewals: filtersInteractions.renewals,
        procurement_members: filtersInteractions.procurementMembers,
        department_ids: filtersInteractions.procurementTeams,
        sort_by: sortBy,
        sort_direction: sortDirection,
        deal_closed: filtersInteractions.deal_closed,
        deal_value_ranges: filtersInteractions.dealValueRanges,
        supplier_onboarded: filtersInteractions.supplierOnboarded,
      })
    );
  }, [filtersInteractions, procurementFilterData, user, searchParams, sortBy, sortDirection, page]);

  if (interactions.length === 0) {
    return (
      <Box sx={{ height: "422px" }}>
        <NoResultCard variant={"filtersInteractions"} />
      </Box>
    );
  }

  FullStory('setProperties', {
    type: 'page',
    properties: {
      pageName: 'Interactions',
    }
  });

  return (
    <Box>
      <Notifications id={2} />
      {hasRedesignedHomepage(user) && <Overview variant={'interactions'}/>}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
        id='interaction-list-header'
      >
        <Box>
          {!isLoading && (
            <ListSummary type="interactions" count={interactions.length} />
          )}
        </Box>

        <Box sx={{ width: '160px' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel htmlFor="sortedBy" color={'secondary'}>
              Sort by
            </InputLabel>
            <Select
              sx={{ borderRadius: '10px' }}
              color={'secondary'}
              size={'small'}
              id={'sortedBy'}
              label={'Sort by'}
              value={selectedSort}
              onChange={handleSortChange}
            >
              <MenuItem value={'urgency-desc'}>Recommended</MenuItem>
              <MenuItem value={'start_time-desc'}>Recent date</MenuItem>
              <MenuItem value={'start_time-asc'}>Earliest date</MenuItem>
              <MenuItem value={'supplier_name-asc'}>Supplier: A-Z</MenuItem>
              <MenuItem value={'supplier_name-desc'}>Supplier: Z-A</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {isLoading
          ? [0, 1, 2, 3].map((_, index) => (
              <LoadingSkeleton variant={'interactionItem'} key={index} />
            ))
          : interactions.map((interaction) => (
              <InteractionItem key={interaction.id} interaction={interaction} />
            ))}
      </Box>
    </Box>
  );
};

export default Interactions;
