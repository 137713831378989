import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Avatar, Box, Chip, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { getInitials } from '../utils/functions';

function FilterChipAutocomplete({ options, label, placeholder, onSelectionChange, procurementMembers, showInitials, restrictValues, optionalStyles, removeArrow, hideDropdown }) {
    const [selectedOptions, setSelectedOptions] = useState(() =>
        options?.filter((option) => procurementMembers?.includes(option.value)) || []
    );

    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const autocompleteRef = useRef(null);

    const [hoveredChip, setHoveredChip] = useState(null);

    useEffect(() => {
        const newSelectedOptions = options?.filter((option) =>
            procurementMembers?.includes(option.value)
        ) || [];
        setSelectedOptions(newSelectedOptions);
    }, [procurementMembers, options]);

    const handleDelete = (optionToDelete) => () => {
        const newSelectedOptions = selectedOptions.filter(
            (option) => option.value !== optionToDelete.value
        );

        setSelectedOptions(newSelectedOptions);
        onSelectionChange(newSelectedOptions.map((opt) => opt.value));
    };

    const availableOptions = options?.filter((option) => !selectedOptions.some((selected) => selected.value === option.value)) || [];
    const isDisabled = availableOptions.length === 0;

    const handleAddClick = () => {
        if (!isDisabled) {
            setOpen(true);
        }
    };

    const getChipLabel = (option) => {
        return option.label || option.value;
    };

    const renderOption = (props, option) => {
        return (
            <Box key={`${option.label}-${option.value}`} component="li" {...props} sx={{ fontSize: "14px" }}>
                {showInitials && option.label ? (
                    <Avatar sx={{ bgcolor: '#e3e1ea', color: '#514689', marginRight: '4px', fontSize: "13px", width: "24px", height: "24px" }}>
                        {getInitials(option.label)}
                    </Avatar>
                ) : null}
                {option.label || option.value}
            </Box>
        );
    };

    return (
        <Box sx={optionalStyles || { width: '100%' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', alignItems: 'center', marginBottom: '8px' }}>
                {selectedOptions.map((option) => (
                    <Chip
                        key={option.value}
                        avatar={
                            option.label && showInitials ? (
                                <Avatar sx={{ bgcolor: '#e3e1ea', color: '#514689 !important' }}>{getInitials(option.label)}</Avatar>
                            ) : undefined
                        }
                        label={getChipLabel(option)}
                        onMouseEnter={() => setHoveredChip(option.value)}
                        onMouseLeave={() => setHoveredChip(null)}
                        onDelete={hoveredChip === option.value ? handleDelete(option) : null}
                        sx={{
                            marginBottom: '8px',
                            background: 'white',
                            border: '1px solid rgba(240, 238, 247, 1)',
                            '&:hover': {
                                backgroundColor: 'rgba(101, 88, 177, 0.04)',
                            }
                        }}
                    />
                ))}
            </Box>
            {!hideDropdown && (
                <Autocomplete
                    multiple
                    renderOption={renderOption}
                    id={`autocomplete-${label.replace(/\s+/g, '-').toLowerCase()}`}
                    options={availableOptions}
                    getOptionLabel={(option) => option.label || option.value}
                    freeSolo={!restrictValues}
                autoHighlight={restrictValues}
                value={selectedOptions}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                ref={autocompleteRef}
                onChange={(event, newValue) => {
                    setSelectedOptions(newValue);
                    onSelectionChange(newValue);
                }}
                renderTags={() => { }}
                fullWidth
                forcePopupIcon={removeArrow ? false : 'auto'}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder={isDisabled ? '' : placeholder}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                !isDisabled && (
                                    <InputAdornment position="start">
                                        <AddIcon onClick={handleAddClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>
                                )
                            ),
                            disableUnderline: true,
                        }}
                        disabled={isDisabled}
                        sx={{
                            '& .MuiInputBase-root': {
                                border: 'none',
                                padding: 0,
                            },
                            '& .Mui-disabled': {
                                color: 'text.primary',
                            }
                        }}
                    />
                )}
                disableClearable
                    disabled={isDisabled}
                />
            )}
        </Box>
    );
}

export default FilterChipAutocomplete;
