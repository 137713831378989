import { Box } from '@mui/material';
import CustomCard from '../../common/CustomCard';
import Logo from '../../common/Logo';
import { SupplierAlternativeVote } from './SupplierAlternativeItem';

const SupplierAlternativeExternalItem = ({ supplier, handleStar, handleHide }) => {
  const handleClick = () => {
    if (supplier.website) {
      window.open(supplier.website, '_blank');
    }
  };

  return (
    <CustomCard isHover={supplier.website ? true : false} onClick={handleClick}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid #F2F2F7',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              width: 'calc(100% - 30px)',
            }}
          >
            <Logo size={54} img={supplier.image_url} />
            <Box
              color={(theme) => theme.palette.text.main}
              sx={{
                lineHeight: '150%',
              }}
              fontSize={(theme) => theme.typography.h6}
            >
              {supplier.company_name}
            </Box>
          </Box>
          <SupplierAlternativeVote supplier={supplier} handleStar={handleStar} handleHide={handleHide} isExternal />
        </Box>
        <Box sx={{ padding: '16px'}}>
          <Box
            color={(theme) => theme.palette.text.secondary}
            fontSize={(theme) => theme.typography.body1}
          >
            {supplier.description}
          </Box>
          {Boolean(supplier.tags?.length) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '10px',
                maxHeight: '60px',
                overflow: 'hidden',
                width: '100%',
                marginTop: '16px',
              }}
            >
              {supplier.tags?.map((item, index) => (
                <Box
                  key={`alternative-tag-${index}`}
                  sx={{
                    border: '1px solid #A7A4E0',
                    borderRadius: '100px',
                    padding: '4px 10px',
                    fontSize: '13px',
                    color: '#6558B1',
                  }}
                >
                  {item}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </CustomCard>
  );
};

export default SupplierAlternativeExternalItem;
