import {
  ApartmentRounded,
  ExpandMore,
  ShortcutOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from '../../common/CustomCard';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import StyledIcon from '../../common/StyledIcon';
import { ReactComponent as AiSparkle } from '../../common/icons/aiSparkle.svg';
import { formatCurrency } from '../../utils/functions';
import { firstTimeframeWithData } from './SupplierDetailsSpend';

export const hasSupplierHierarchy = (supplier) => {
  if (!supplier.company_hierarchy) return false;
  if (
    !supplier.company_hierarchy?.parent?.uuid &&
    !supplier.company_hierarchy?.children?.length
  )
    return false;
  return true;
};

const formatTitleTotal = (timeframe) => {
  return timeframe === 'max' ? 'all time' : `in last ${timeframe} months`;
};

const formatSupplierNames = (invoices, timeframe, supplierName, accounts) => {
  if (!accounts) {
    return { total: 0, combinedInvoices: [] };
  }

  let total = 0;
  const startDate =
    timeframe === 'max'
      ? moment(invoices[0]?.issue_date || moment()).subtract(1, 'day')
      : moment().subtract(timeframe, 'month');

  const invoiceAccounts = accounts.reduce((acc, account) => {
    const description =
      account?.description_ai?.toLowerCase() === 'unknown' ? '' : account.description_ai;
    const categories = description ? account.categories_ai : [];
    acc[account.name || supplierName] = {
      name: account.name || supplierName,
      value: 0,
      categories,
      description,
    };
    return acc;
  }, {});

  [...(invoices?.length ? invoices : [])]
    .filter((entry) => entry.status === 'Paid In Full')
    .sort((a, b) => new Date(a.issue_date) - new Date(b.issue_date))
    .filter((entry) => {
      if (timeframe === 'max') return true;
      return !moment(entry.issue_date).isBefore(startDate);
    })
    .forEach((entry) => {
      const name = entry.name || supplierName;
      if (!invoiceAccounts[name]) {
        invoiceAccounts[name] = { name: name, value: 0 };
      }
      total += Number(entry.amount_usd_total || 0);
      invoiceAccounts[name].value += Number(entry.amount_usd_total || 0);
    });

  const combinedInvoices = Object.values(invoiceAccounts);
  return { total, combinedInvoices };
};

export const tooltipDescripton = (description, categories) => {
  return (
    <CustomCard height={'100%'}>
      <Box padding={'16px'}>
        <Box display={'flex'} gap={'6px'}>
          <Box padding={'3px 2px'}>
            <AiSparkle />
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
            <Box
              fontSize={(theme) => theme.typography.caption}
              color={(theme) => theme.palette.text.secondary}
            >
              {description}
            </Box>
            <Box display={'flex'} gap={'8px'} flexWrap={'wrap'}>
              {categories.map((category, index) => (
                <Box
                  key={`${category}-${index}`}
                  sx={{
                    border: '1px solid #A7A4E0',
                    borderRadius: '100px',
                    padding: '3px 10px',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: '#6558B1',
                    textWrap: 'no-wrap',
                  }}
                >
                  {category}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};

const SupplierAccountItem = ({ name, value }) => {
  return (
    <Box display={'flex'} alignContent={'center'} gap={'4px'} key={name}>
      <Box
        fontSize={(theme) => theme.typography.subtitle2}
        color={(theme) => theme.palette.text.primary}
        width={'250px'}
      >
        {name}
      </Box>
      <Box
        fontSize={(theme) => theme.typography.body2}
        color={(theme) => theme.palette.text.primary}
      >
        {formatCurrency(value)}
      </Box>
    </Box>
  );
};

const getInvoices = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/suppliers/${id}/invoices`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      credentials: 'include',
    },
  );
  if (response.ok) {
    return await response.json();
  } else {
    return [];
  }
};

const getAccounts = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/suppliers/${id}/accounts`,
  );
  if (response.ok) {
    const data = await response.json();
    if (data?.accounts) {
      return data.accounts;
    } else {
      return [];
    }
  } else {
    return [];
  }
};


const getRelatedInvoices = async (hierarchy) => {
  if (!hierarchy) return { parentInvoices: [], parentAccounts: [], childInvoices: [], childAccounts: [] };
  let parentInvoices = [];
  let parentAccounts = [];
  let childInvoices = [];
  let childAccounts = [];
  if (hierarchy.parent && hierarchy.parent.uuid) {
    parentInvoices = await getInvoices(hierarchy.parent.internal_uuid);
    parentAccounts = await getAccounts(hierarchy.parent.internal_uuid);
  }

  if (hierarchy.children) {
    childInvoices = await Promise.all(
      hierarchy.children.map((child) => getInvoices(child.internal_uuid)),
    );
    childAccounts = await Promise.all(
      hierarchy.children.map((child) => getAccounts(child.internal_uuid)),
    );
  }

  const childInvoicesData = hierarchy.children.map((child, index) => ({
    ...child,
    invoices: childInvoices[index],
  }));
  const childAccountsData = hierarchy.children.map((child, index) => ({
    ...child,
    accounts: childAccounts[index],
  }));

  return { parentInvoices, parentAccounts, childInvoices: childInvoicesData, childAccounts: childAccountsData };
};

const SupplierDetailsStructure = ({
  invoices,
  accounts,
  timeframe,
  sidebar,
  supplier,
  summary,
  handleClick,
}) => {
  const [spendTotal, setSpendTotal] = useState();
  const [invoiceAccounts, setInvoiceAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [localTimeframe, setLocalTimeframe] = useState(timeframe);
  const [parentInvoices, setParentInvoices] = useState([]);
  const [parentAccounts, setParentAccounts] = useState([]);
  const [childInvoices, setChildInvoices] = useState([]);
  const [childAccounts, setChildAccounts] = useState([]);
  const [supplierHierarchy, setSupplierHierarchy] = useState({});
  const [supplierHierarchyLoading, setSupplierHierarchyLoading] =
    useState(true);
  const [
    supplierHierarchyInvoicesLoading,
    setSupplierHierarchyInvoicesLoading,
  ] = useState(true);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { page } = useSelector((state) => state.appSlice);

  useEffect(() => {
    if (!invoices && !accounts && supplier.id && hasSupplierHierarchy(supplier)) {
      setSpendTotal(0);
      setInvoiceAccounts([]);
      setFilteredAccounts([]);
      setLocalTimeframe(timeframe);
      return;
    }

    const calculatedTimeframe = summary
      ? firstTimeframeWithData(invoices)
      : timeframe;
    setLocalTimeframe(calculatedTimeframe);
    const { total, combinedInvoices } = formatSupplierNames(
      invoices,
      calculatedTimeframe,
      supplier.name,
      accounts,
    );
    const sortedAccounts = combinedInvoices.sort((a, b) => b.value - a.value);
    const filteredCombinedInvoices = summary
      ? sortedAccounts.slice(0, 5)
      : sortedAccounts;
    setSpendTotal(total);
    setInvoiceAccounts(sortedAccounts);
    setFilteredAccounts(filteredCombinedInvoices);
  }, [invoices, accounts, timeframe, summary, supplier.id]);

  useEffect(() => {
    setSupplierHierarchyInvoicesLoading(true);
    if (!supplier || !hasSupplierHierarchy(supplier)) {
      setSupplierHierarchy({});
      setParentInvoices([]);
      setParentAccounts([]);
      setChildInvoices([]);
      setChildAccounts([]);
      setSupplierHierarchyInvoicesLoading(false);
      return;
    }

    getRelatedInvoices(supplier?.company_hierarchy)
      .then(({ parentInvoices, parentAccounts, childInvoices, childAccounts }) => {
        setParentInvoices(parentInvoices);
        setParentAccounts(parentAccounts);
        setChildInvoices(childInvoices);
        setChildAccounts(childAccounts);
        setSupplierHierarchyInvoicesLoading(false);
      })
      .catch(() => {
        setSupplierHierarchy({});
        setParentInvoices([]);
        setChildInvoices([]);
        setParentAccounts([]);
        setChildAccounts([]);
        setSupplierHierarchyInvoicesLoading(false);
      });
  }, [supplier]);

  useEffect(() => {
    if (supplierHierarchyInvoicesLoading || !localTimeframe || !supplier)
      return;

    if (!parentInvoices || !childInvoices || !parentAccounts || !childAccounts) {
      setSupplierHierarchyLoading(false);
      return;
    }
    let parentInvoiceData = null;
    if (supplier?.company_hierarchy?.parent && parentInvoices.length > 0) {
      let { combinedInvoices } = formatSupplierNames(
        parentInvoices,
        localTimeframe,
        supplier.company_hierarchy.parent.name,
        parentAccounts,
      );
      parentInvoiceData = combinedInvoices.sort((a, b) => b.value - a.value);
    }
    const childInvoiceData = childInvoices.map((child) => {
      const foundAccount = childAccounts.find((account) => account.internal_uuid === child.internal_uuid);
      return {
        ...child,
        ...(child.invoices.length > 0
          ? formatSupplierNames(child.invoices, localTimeframe, child.name, foundAccount?.accounts || [])
          : {}),
      };
    });
    const formattedChildInvoices = childInvoiceData.map((child) => ({
      name: child.name,
      internal_uuid: child.internal_uuid,
      accounts: child.combinedInvoices
        ? child.combinedInvoices.sort((a, b) => b.value - a.value)
        : [],
    }));

    setSupplierHierarchy({
      parentFormatted: supplier.company_hierarchy.parent
        ? {
            accounts: parentInvoiceData || [],
            name: supplier.company_hierarchy.parent.name,
            internal_uuid: supplier.company_hierarchy.parent.internal_uuid,
          }
        : null,
      childrenFormatted: formattedChildInvoices,
    });
    setSupplierHierarchyLoading(false);
  }, [
    parentInvoices,
    parentAccounts,
    childInvoices,
    childAccounts,
    localTimeframe,
    supplier,
    supplierHierarchyInvoicesLoading,
  ]);

  if (supplierHierarchyLoading || supplierHierarchyInvoicesLoading) {
    return <LoadingSkeleton variant={'interactionItem'} />;
  }

  const handleSupplierClick = (supplierId) => {
    const detailsLink = `${
      page === 'overview' ? '' : `/${page}`
    }/details/supplier/${supplierId}${
      pathname.includes('fullscreen') ? '/fullscreen' : ''
    }/5${search}`;
    handleClick();
    navigate(detailsLink);
  };

  return (
    <CustomCard height={'100%'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'16px'}
        gap={'16px'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {!Boolean(summary) && (
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) =>
                sidebar ? theme.typography.h6 : theme.typography.h5
              }
            >
              Structure & spend
            </Box>
          )}
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) =>
              summary ? theme.typography.h6 : theme.typography.subtitle1
            }
            display={'flex'}
            alignItems={'center'}
            gap={'4px'}
          >
            {formatCurrency(spendTotal)}
            <Typography
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.secondary}
            >
              {formatTitleTotal(localTimeframe)}
            </Typography>
          </Box>
          {Boolean(summary) && (
            <Link
              sx={{ cursor: 'pointer' }}
              color="secondary"
              fontSize={(theme) => theme.typography.subtitle2}
              underline="hover"
              onClick={() => {
                summary();
              }}
            >
              See structure & spend
            </Link>
          )}
        </Box>
        {supplierHierarchy.parentFormatted && (
          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1.5px solid',
              borderRadius: '8px',
              borderColor: '#E0E0E0',
              padding: '0 16px',
              '&.Mui-expanded': {
                margin: '0 !important',
                borderColor: '#E0E0E0',
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                supplierHierarchy.parentFormatted.accounts?.length > 0 ? (
                  <StyledIcon component={ExpandMore} color="icon" />
                ) : null
              }
              sx={{
                pointerEvents:
                  supplierHierarchy.parentFormatted.accounts?.length === 0
                    ? 'none'
                    : 'auto',
                display: 'flex',
                padding: 0,
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '0 !important',
                  borderBottom: '1px solid #E0E0E0',
                },
                '& .MuiAccordionSummary-content': {
                  gap: '4px',
                  alignItems: 'center',
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: '16px 0',
                  alignItems: 'center',
                },
              }}
            >
              <Box
                width={'250px'}
                display={'flex'}
                flexWrap={'wrap'}
                alignItems={'center'}
                gap={'8px'}
              >
                <StyledIcon component={ApartmentRounded} color="icon" />
                <Link
                  color="secondary"
                  fontSize={(theme) => theme.typography.subtitle1}
                  underline="hover"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                  }}
                  onClick={() =>
                    handleSupplierClick(
                      supplierHierarchy.parentFormatted.internal_uuid,
                    )
                  }
                >
                  {supplierHierarchy.parentFormatted.name}
                </Link>
                <Box
                  fontSize={(theme) => theme.typography.caption}
                  color={(theme) => theme.palette.text.primary}
                  sx={{
                    padding: '2px 4px',
                    borderRadius: '4px',
                    background: '#00000014',
                  }}
                >
                  Parent
                </Box>
              </Box>
              {supplierHierarchy.parentFormatted.accounts?.length > 0 && (
                <Box>{`${
                  supplierHierarchy.parentFormatted.accounts?.length
                } account${
                  supplierHierarchy.parentFormatted.accounts?.length === 1
                    ? ''
                    : 's'
                }`}</Box>
              )}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                margin: '0',
                padding: '0',
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                padding={'16px 0'}
                gap={'8px'}
              >
                {supplierHierarchy.parentFormatted.accounts.map(
                  ({ name, value, description, categories }) => {
                    if (description) {
                      return (
                        <Accordion
                          elevation={0}
                          key={`${name}-${value}`}
                          sx={{
                            padding: '0px',
                            '&:before': {
                              display: 'none',
                            },
                            '&.Mui-expanded': {
                              margin: '0 !important',
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <StyledIcon component={ExpandMore} color="icon" />
                            }
                            sx={{
                              display: 'flex',
                              padding: 0,
                              '&.Mui-expanded': {
                                margin: '0 !important',
                                minHeight: '0 !important',
                              },
                              '& .MuiAccordionSummary-content': {
                                alignItems: 'center',
                                margin: 0,
                              },
                              '& .MuiAccordionSummary-content.Mui-expanded': {
                                margin: '16px 0',
                                alignItems: 'center',
                              },
                            }}
                          >
                            <SupplierAccountItem
                              name={name}
                              value={value}
                            />
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              margin: '0',
                              padding: '0',
                              borderTop: '0px',
                              paddingBottom: '16px',
                            }}
                          >
                            {tooltipDescripton(description, categories)}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                    return (
                      <SupplierAccountItem
                        name={name}
                        value={value}
                      />
                    );
                  },
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
          {supplierHierarchy.parentFormatted && (
            <Box paddingTop={'16px'}>
              <StyledIcon
                sx={{
                  rotate: '180deg',
                  transform: 'scale(-1, 1)',
                }}
                component={ShortcutOutlined}
                color="icon"
              />
            </Box>
          )}
          <Accordion
            defaultExpanded={filteredAccounts?.length > 0}
            sx={{
              boxShadow: 'none',
              border: '1.5px solid',
              borderRadius: '8px',
              borderColor: '#6558B1',
              padding: '0 16px',
              flexGrow: '1',
              '&.Mui-expanded': {
                margin: '0 !important',
                borderColor: '#6558B1',
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                filteredAccounts?.length === 0 ? null : (
                  <StyledIcon component={ExpandMore} color="icon" />
                )
              }
              sx={{
                pointerEvents: filteredAccounts?.length === 0 ? 'none' : 'auto',
                display: 'flex',
                padding: 0,
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '0 !important',
                  borderBottom: '1px solid #E0E0E0',
                },
                '& .MuiAccordionSummary-content': {
                  gap: '4px',
                  alignItems: 'center',
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: '16px 0',
                  alignItems: 'center',
                },
              }}
            >
              <Box
                width={'250px'}
                display={'flex'}
                flexWrap={'wrap'}
                alignItems={'center'}
                gap={'8px'}
              >
                <StyledIcon component={ApartmentRounded} color="icon" />
                <Box
                  fontSize={(theme) => theme.typography.subtitle1}
                  color={(theme) => theme.palette.text.primary}
                >
                  {supplier.name}
                </Box>
                <Box
                  fontSize={(theme) => theme.typography.caption}
                  color={(theme) => theme.palette.text.primary}
                  sx={{
                    padding: '2px 4px',
                    borderRadius: '4px',
                    background: '#00000014',
                  }}
                >
                  Currently viewing
                </Box>
              </Box>
              <Box>
                {invoiceAccounts.length > 0 &&
                  `${invoiceAccounts.length} account${
                    invoiceAccounts.length === 1 ? '' : 's'
                  }`}
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                margin: '0',
                padding: '0',
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                padding={'16px 0'}
                gap={'8px'}
              >
                {filteredAccounts.map(
                  ({ name, value, description, categories }) => {
                    if (description) {
                      return (
                        <Accordion
                          elevation={0}
                          key={`${name}-${value}`}
                          sx={{
                            padding: '0px',
                            '&:before': {
                              display: 'none',
                            },
                            '&.Mui-expanded': {
                              margin: '0 !important',
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <StyledIcon component={ExpandMore} color="icon" />
                            }
                            sx={{
                              display: 'flex',
                              padding: 0,
                              '&.Mui-expanded': {
                                margin: '0 !important',
                                minHeight: '0 !important',
                              },
                              '& .MuiAccordionSummary-content': {
                                alignItems: 'center',
                                margin: 0,
                              },
                              '& .MuiAccordionSummary-content.Mui-expanded': {
                                margin: '16px 0',
                                alignItems: 'center',
                              },
                            }}
                          >
                            <SupplierAccountItem
                              name={name}
                              value={value}
                            />
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              margin: '0',
                              padding: '0',
                              borderTop: '0px',
                              paddingBottom: '16px',
                            }}
                          >
                            {tooltipDescripton(description, categories)}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                    return (
                      <SupplierAccountItem
                        name={name}
                        value={value}
                      />
                    );
                  },
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {supplierHierarchy.childrenFormatted && (
          <>
            {supplierHierarchy.childrenFormatted.map((child) => {
              return (
                <Box
                  key={child.id}
                  display={'flex'}
                  flexDirection={'row'}
                  gap={'8px'}
                >
                  <Box paddingTop={'16px'}>
                    <StyledIcon
                      sx={{
                        rotate: '180deg',
                        transform: 'scale(-1, 1)',
                      }}
                      component={ShortcutOutlined}
                      color="icon"
                    />
                  </Box>
                  <Accordion
                    sx={{
                      boxShadow: 'none',
                      border: '1.5px solid',
                      borderRadius: '8px',
                      borderColor: '#E0E0E0',
                      padding: '0 16px',
                      flexGrow: '1',
                      '&.Mui-expanded': {
                        margin: '0 !important',
                        borderColor: '#E0E0E0',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        child.accounts?.length > 0 ? (
                          <StyledIcon component={ExpandMore} color="icon" />
                        ) : null
                      }
                      sx={{
                        pointerEvents:
                          child.accounts?.length === 0 ? 'none' : 'auto',
                        display: 'flex',
                        padding: 0,
                        '&.Mui-expanded': {
                          margin: '0 !important',
                          minHeight: '0 !important',
                          borderBottom: '1px solid #E0E0E0',
                        },
                        '& .MuiAccordionSummary-content': {
                          gap: '4px',
                          alignItems: 'center',
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                          margin: '16px 0',
                          alignItems: 'center',
                        },
                      }}
                    >
                      <Box
                        width={'250px'}
                        display={'flex'}
                        flexWrap={'wrap'}
                        alignItems={'center'}
                        gap={'8px'}
                      >
                        <StyledIcon component={ApartmentRounded} color="icon" />
                        <Link
                          color="secondary"
                          fontSize={(theme) => theme.typography.subtitle1}
                          underline="hover"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            pointerEvents: 'auto',
                          }}
                          onClick={() =>
                            handleSupplierClick(child.internal_uuid)
                          }
                        >
                          {child.name}
                        </Link>
                        <Box
                          fontSize={(theme) => theme.typography.caption}
                          color={(theme) => theme.palette.text.primary}
                          sx={{
                            padding: '2px 4px',
                            borderRadius: '4px',
                            background: '#00000014',
                          }}
                        >
                          Subsidiary
                        </Box>
                      </Box>
                      {Boolean(child.accounts?.length) && (
                        <Box>{`${child.accounts.length} account${
                          child.accounts.length === 1 ? '' : 's'
                        }`}</Box>
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        margin: '0',
                        padding: '0',
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        padding={'16px 0'}
                        gap={'8px'}
                      >
                        {child.accounts.map(
                          ({ name, value, description, categories }) => {
                            if (description) {
                              return (
                                <Accordion
                                  elevation={0}
                                  key={`${name}-${value}`}
                                  sx={{
                                    padding: '0px',
                                    '&:before': {
                                      display: 'none',
                                    },
                                    '&.Mui-expanded': {
                                      margin: '0 !important',
                                    },
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <StyledIcon
                                        component={ExpandMore}
                                        color="icon"
                                      />
                                    }
                                    sx={{
                                      display: 'flex',
                                      padding: 0,
                                      '&.Mui-expanded': {
                                        margin: '0 !important',
                                        minHeight: '0 !important',
                                      },
                                      '& .MuiAccordionSummary-content': {
                                        alignItems: 'center',
                                        margin: 0,
                                      },
                                      '& .MuiAccordionSummary-content.Mui-expanded':
                                        {
                                          margin: '16px 0',
                                          alignItems: 'center',
                                        },
                                    }}
                                  >
                                    <SupplierAccountItem
                                      name={name}
                                      value={value}
                                    />
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      margin: '0',
                                      padding: '0',
                                      borderTop: '0px',
                                      paddingBottom: '16px',
                                    }}
                                  >
                                    {tooltipDescripton(description, categories)}
                                  </AccordionDetails>
                                </Accordion>
                              );
                            }
                            return (
                              <SupplierAccountItem
                                name={name}
                                value={value}
                              />
                            );
                          },
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    </CustomCard>
  );
};

export default SupplierDetailsStructure;
