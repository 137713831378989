import { Box } from '@mui/material';
import React from 'react';
import { isActiveInteraction } from '../components/suppliers/SupplierItem';
import { ReactComponent as ActiveContractSmall } from './icons/activeContractSmall.svg';
import { ReactComponent as ActiveInteraction } from './icons/activeInteraction.svg';
import { ReactComponent as OnboardedSupplier } from './icons/onboardedSupplier.svg';

const SupplierBadges = ({ supplier, gap, manualActiveInteraction, iconOnly }) => {
  return (
    <Box display={'flex'} flexDirection={iconOnly ? 'row' : 'column'} alignItems={iconOnly ? 'center' : 'flex-start'} gap={gap || '16px'}>
      {supplier?.active_account && (
        <Box
          display={'flex'}
          marginBottom={'2px'}
          gap={'6px'}
          alignItems={'center'}
        >
          <OnboardedSupplier />
          {!iconOnly && (
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.secondary}
            >
              Onboarded supplier
            </Box>
          )}
        </Box>
      )}
       {supplier?.active_contract && (
        <Box
          display={'flex'}
          marginBottom={'2px'}
          gap={'6px'}
          alignItems={'center'}
        >
          <ActiveContractSmall />
          {!iconOnly && (
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.secondary}
            >
            Active contract
            </Box>
          )}
        </Box>
      )}
      {isActiveInteraction(supplier) && !manualActiveInteraction && (
        <Box gap={'6px'} alignItems={'center'} display={'flex'}>
          <ActiveInteraction />
          {!iconOnly && (
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.secondary}
          >
            Active interaction
            </Box>
          )}
        </Box>
      )}
      {supplier?.manual_active_interaction && (
        <Box gap={'6px'} alignItems={'center'} display={'flex'}>
          <ActiveInteraction />
          {!iconOnly && (
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.secondary}
            >
              Active interaction
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SupplierBadges;
