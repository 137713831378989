import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ClearFiltersButton from '../../common/ClearFilters';
import CustomChip from '../../common/CustomChip';
import CustomDateRangePickerModal from '../../common/CustomDateRangePickerModal';
import FilterChipAutocomplete from '../../common/FilterChipAutocomplete';
import { updateFiltersDashboard } from '../../store/localStorage';

const timeframeFilters = [
  { label: '6M', value: 6 },
  { label: '1Y', value: 12 },
  { label: '5Y', value: 60 },
];

const interactionTimeframeFilters = [
  { label: '7 days', value: 7 },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
];

const handleFilters = (newFilters, setFilters) => {
  setFilters(newFilters);
  updateFiltersDashboard(newFilters);
};

export const DashboardInteractionFilters = ({ filters, setFilters }) => {
  const [timeframe, setTimeframe] = useState(
    filters.interactionTimeframe || 30,
  );

  useEffect(() => {
    if (filters.interactionTimeframe === timeframe) return;
    handleFilters(
      {
        ...filters,
        interactionTimeframe: timeframe,
      },
      setFilters,
    );
  }, [timeframe]);

  return (
    <Box display={'flex'} gap={'8px'} flexWrap={'wrap'}>
      {interactionTimeframeFilters.map((item) => (
        <CustomChip
          key={item.label}
          label={item.label}
          isActive={timeframe === item.value}
          onClick={() => {
            setTimeframe(item.value);
          }}
        />
      ))}
    </Box>
  );
};

export const DashboardDateFilters = ({ filters, setFilters }) => {
  const [timeframe, setTimeframe] = useState(filters.timeframe);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDatePicker = Boolean(anchorEl);
  const id = openDatePicker ? 'custom-timeframe-popover' : undefined;
  const setOpenDatePicker = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (timeframe !== 'custom' && openDatePicker) setOpenDatePicker(false);
    if (timeframe === 'custom') return;
    if (
      timeframe === filters.timeframe &&
      moment(filters.end_date).isSame(moment(), 'day')
    )
      return;
    const newStartDate = moment().subtract(timeframe, 'month');
    handleFilters(
      {
        ...filters,
        timeframe,
        start_date: newStartDate,
        end_date: moment(),
      },
      setFilters,
    );
  }, [timeframe]);

  const handleCustomDateChange = (newValue) => {
    if (!newValue) return;
    if (
      newValue.start_date.isSame(filters.start_date) &&
      newValue.end_date.isSame(filters.end_date)
    )
      return;
    handleFilters(
      {
        ...newValue,
        timeframe: 'custom',
      },
      setFilters,
    );
    setTimeframe('custom');
  };

  return (
    <Box display={'flex'} gap={'8px'} flexWrap={'wrap'}>
      {timeframeFilters.map((item) => (
        <CustomChip
          key={item.label}
          label={item.label}
          isActive={timeframe === item.value}
          onClick={() => {
            setTimeframe(item.value);
          }}
        />
      ))}
      <CustomChip
        id={id}
        label={'Custom'}
        isActive={timeframe === 'custom'}
        onClick={setOpenDatePicker}
      />
      {openDatePicker && (
        <CustomDateRangePickerModal
          id={id}
          anchorEl={anchorEl}
          open={openDatePicker}
          onChange={handleCustomDateChange}
          filters={filters}
          onClose={handleClose}
        />
      )}
    </Box>
  );
};

export const DashboardTeamFilters = ({ filters, setFilters, filterValues }) => {
  const [teamData, setTeamData] = useState([]);

  const handleTeamChange = (newValue) => {
    handleFilters(
      {
        ...filters,
        team: newValue.map((item) => (item?.value ? item.value : item)),
      },
      setFilters,
    );
  };

  const resetFilters = () => {
    handleFilters(
      {
        ...filters,
        team: [],
      },
      setFilters,
    );
  };

  useEffect(() => {
    if (!filterValues) return;
    setTeamData(filterValues?.team);
  }, [filterValues]);
  if (!Boolean(teamData && teamData.length)) return <></>;

  return (
    <Box display={'flex'} alignItems={'flex-start'}>
      <FilterChipAutocomplete
        options={teamData}
        label="Team"
        placeholder="Team"
        onSelectionChange={handleTeamChange}
        procurementMembers={filters.team}
        showInitials={false}
        restrictValues
        removeArrow
        optionalStyles={{
          display: 'grid',
          gridTemplateColumns: '1fr 250px',
          gap: '10px',
        }}
      />
      <ClearFiltersButton variant="manual" resetFunction={resetFilters} />
    </Box>
  );
};
