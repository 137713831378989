import {
  StarBorderRounded,
  StarRounded,
  VisibilityOff,
} from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomCard from '../../common/CustomCard';
import Logo from '../../common/Logo';
import StyledIcon from '../../common/StyledIcon';
import SupplierBadges from '../../common/SupplierBadges.js';
import TextListWithOverflow from '../../common/TextListWithOverflow.js';
import { formatCurrency } from '../../utils/functions.js';

export const SupplierAlternativeVote = ({
  supplier,
  handleStar,
  handleHide,
  isExternal,
}) => {
  const supplierId = isExternal ? supplier.id : supplier.internal_uuid;
  return (
    <Box marginLeft={'8px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
      >
        {Boolean(!supplier.hidden) && (
          <IconButton
            type="button"
            sx={{ padding: '8px' }}
            onClick={(e) => {
            handleStar(supplierId, isExternal);
            e.stopPropagation();
          }}
        >
          {supplier.starred ? (
            <StyledIcon component={StarRounded} color={'#F7CD34'} />
          ) : (
            <StyledIcon component={StarBorderRounded} color={'icon'} />
            )}
          </IconButton>
        )}
        {Boolean(!supplier.starred) && (
          <IconButton
            type="button"
            sx={{ padding: '8px', marginTop: supplier.hidden ? '0px' : '-10px' }}
            onClick={(e) => {
              handleHide(supplierId, isExternal);
              e.stopPropagation();
            }}
          >
            <StyledIcon
              component={VisibilityOff}
              color={supplier.hidden ? 'icon_error' : 'icon'}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const SupplierAlternativeItem = ({
  supplier,
  sidebar,
  handleStar,
  handleHide,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');
  const [categories, setCategories] = useState([]);
  const [teams, setTeams] = useState([]);
  const { page } = useSelector((state) => state.appSlice);

  useEffect(() => {
    const teams = [
      ...(supplier?.financial_stats?.departments || []),
      ...(supplier?.workflow_stats?.departments || []),
    ];

    const departmentsInvolved = [...new Set(teams)];

    setCategories(supplier?.categories_ai || supplier?.categories || []);
    setTeams(departmentsInvolved);
  }, [supplier]);

  const detailsLink =
    page === 'overview'
      ? `/details/supplier/${supplier.id}${sidebar ? '' : '/fullscreen'}`
      : `/${page}/details/supplier/${supplier.id}${
          sidebar ? '' : '/fullscreen'
        }${searchQuery ? `?q=${searchQuery}` : ''}`;

  return (
    <CustomCard isHover={true} onClick={() => navigate(detailsLink)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #F2F2F7',
            justifyContent: 'space-between',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              width: 'calc(100% - 30px)',
            }}
          >
            <Logo size={54} img={supplier.image_url} />
            <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'4px'}
                alignItems={'center'}
              >
                <Box
                  color={(theme) => theme.palette.text.main}
                  sx={{
                    lineHeight: '150%',
                  }}
                  fontSize={(theme) => theme.typography.h6}
                >
                  {supplier.name}
                </Box>
              </Box>
              {Boolean(supplier.onboarded_at) && (
                <Box
                  fontSize={(theme) => theme.typography.body2}
                  color={(theme) => theme.palette.text.medium}
                >
                  {`Supplier since ${moment(supplier.onboarded_at).format(
                    'MMM DD, YYYY',
                  )}`}
                </Box>
              )}
            </Box>
          </Box>
          <Box display={'flex'} gap={'4px'} alignItems={'center'}>
            <Box
              display={'flex'}
              paddingRight={'16px'}
              borderRight={'1px solid #F2F2F7'}
              alignItems={'center'}
            >
              <SupplierBadges
                supplier={supplier}
                gap={'8px'}
                manualActiveInteraction
                iconOnly
              />
            </Box>
            <SupplierAlternativeVote
              supplier={supplier}
              handleStar={handleStar}
              handleHide={handleHide}
            />
          </Box>
        </Box>
        {Boolean(categories.length) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '10px',
              maxHeight: '60px',
              overflow: 'hidden',
              width: '100%',
              padding: '16px',
              borderBottom: '1px solid #F2F2F7',
            }}
          >
            {categories.map((item, index) => (
              <Box
                key={`category-${index}`}
                sx={{
                  border: '1px solid #A7A4E0',
                  borderRadius: '100px',
                  padding: '4px 10px',
                  fontSize: '13px',
                  color: '#6558B1',
                }}
              >
                {item}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box sx={{ minHeight: '50px', padding: '16px', display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '143px',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.primary}
            fontSize={(theme) => theme.typography.caption}
          >
            <Box
              component={'span'}
              sx={{
                backgroundColor: '#00000014',
                padding: '2px 4px',
                borderRadius: '4px',
              }}
            >
              Last 12 month spend
            </Box>
          </Box>
          <Box
            color={(theme) => theme.palette.text.primary}
            fontSize={(theme) => theme.typography.body1}
          >
            {formatCurrency(supplier.total_spend, true)}
          </Box>
        </Box>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingRight: '8px',
          }}
        >
          {Boolean(teams?.length) && (
            <Box
              color={(theme) => theme.palette.text.medium}
              fontSize={(theme) => theme.typography.body2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                borderLeft: '1px solid #F2F2F7',
                padding: '0 8px',
              }}
            >
              <Box
                color={(theme) => theme.palette.text.primary}
                fontSize={(theme) => theme.typography.caption}
              >
                <Box
                  component={'span'}
                  sx={{
                    backgroundColor: '#00000014',
                    padding: '2px 4px',
                    borderRadius: '4px',
                  }}
                >
                  Teams
                </Box>
              </Box>
              <Box
                color={(theme) => theme.palette.text.primary}
                fontSize={(theme) => theme.typography.body1}
              >
                <TextListWithOverflow strings={teams} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </CustomCard>
  );
};

export default SupplierAlternativeItem;
