import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { Box, Collapse, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import StyledIcon from './StyledIcon';

const sectionStyles = {
  border: '1px solid #DEDBEC',
  borderRadius: '12px',
  padding: '16px',
};

function SupplierDetailsCompanyDetails({
  supplier,
  title = 'Company details',
  customStyle = {},
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box
      fontSize={(theme) => theme.typography.body2}
      sx={{ ...customStyle, ...sectionStyles }}
    >
      <Box sx={{ paddingBottom: '16px' }}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {supplier.headquarters && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            fontSize={(theme) => theme.typography.subtitle2}
            sx={{ width: '33%' }}
          >
            Headquarters
          </Box>
          <Box sx={{ width: '66%' }}>{supplier.headquarters}</Box>
        </Box>
      )}
      {supplier.website && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            fontSize={(theme) => theme.typography.subtitle2}
            sx={{ width: '33%' }}
          >
            Website
          </Box>
          <Link
            href={supplier.website}
            target={'_blank'}
            sx={{ width: '66%', color: '#2998FF', textDecoration: 'none' }}
          >
            {supplier.website?.replace(/^\/\//, '')}
          </Link>
        </Box>
      )}
      {supplier.num_employees_range && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            fontSize={(theme) => theme.typography.subtitle2}
            sx={{ width: '33%' }}
          >
            Number of employees
          </Box>
          <Box sx={{ width: '66%' }}>
            {supplier.num_employees_range === '10001 - 0'
              ? '10001+'
              : supplier.num_employees_range}
          </Box>
        </Box>
      )}
      {supplier.min_revenue_estimative && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            fontSize={(theme) => theme.typography.subtitle2}
            sx={{ width: '33%' }}
          >
            Estimated revenue
          </Box>
          <Box sx={{ width: '66%' }}>{supplier.min_revenue_estimative}</Box>
        </Box>
      )}
      {Boolean(!supplier.description_ai && supplier.foundation_year) && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', paddingBottom: '8px' }}
        >
          <Box
            fontSize={(theme) => theme.typography.subtitle2}
            sx={{ width: '33%' }}
          >
            Founded
          </Box>
          <Box sx={{ width: '33%' }}>{supplier.foundation_year}</Box>
        </Box>
      )}
      {Boolean(
        !supplier.description_ai &&
          supplier.diversity_spotlights &&
          supplier.diversity_spotlights.length !== 0,
      ) && (
        <Box sx={{ display: 'flex', paddingBottom: '8px' }}>
          <Box
            fontSize={(theme) => theme.typography.subtitle2}
            sx={{ width: '33%' }}
          >
            Diversity spotlight
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '66%',
              flexWrap: 'wrap',
              gap: '10px',
              marginTop: '-2px',
            }}
          >
            {supplier.diversity_spotlights?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  border: '1px solid rgba(2, 136, 209, 0.5)',
                  borderRadius: '100px',
                  padding: '4px 10px',
                  fontSize: '13px',
                  color: '#0288D1',
                }}
              >
                {item}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <>
          {supplier.foundation_year && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '8px',
              }}
            >
              <Box
                fontSize={(theme) => theme.typography.subtitle2}
                sx={{ width: '33%' }}
              >
                Founded
              </Box>
              <Box sx={{ width: '33%' }}>{supplier.foundation_year}</Box>
            </Box>
          )}
          {supplier.short_description && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '8px',
              }}
            >
              <Box
                fontSize={(theme) => theme.typography.subtitle2}
                sx={{ width: '33%' }}
              >
                Description
              </Box>
              <Box sx={{ width: '66%' }}>{supplier.short_description}</Box>
            </Box>
          )}
          {supplier.categories && supplier.categories.length !== 0 && (
            <Box sx={{ display: 'flex', paddingBottom: '8px' }}>
              <Box
                fontSize={(theme) => theme.typography.subtitle2}
                sx={{ width: '33%' }}
              >
                Industries
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '66%',
                  flexWrap: 'wrap',
                  gap: '10px',
                  marginTop: '-2px',
                }}
              >
                {supplier.categories?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: '1px solid rgba(2, 136, 209, 0.5)',
                      borderRadius: '100px',
                      padding: '4px 10px',
                      fontSize: '13px',
                      color: '#0288D1',
                    }}
                  >
                    {item}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {supplier.diversity_spotlights &&
            supplier.diversity_spotlights.length !== 0 && (
              <Box sx={{ display: 'flex', paddingBottom: '8px' }}>
                <Box
                  fontSize={(theme) => theme.typography.subtitle2}
                  sx={{ width: '33%' }}
                >
                  Diversity spotlight
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '66%',
                    flexWrap: 'wrap',
                    gap: '10px',
                    marginTop: '-2px',
                  }}
                >
                  {supplier.diversity_spotlights?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        border: '1px solid rgba(2, 136, 209, 0.5)',
                        borderRadius: '100px',
                        padding: '4px 10px',
                        fontSize: '13px',
                        color: '#0288D1',
                      }}
                    >
                      {item}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          <Box
            color={(theme) => theme.palette.text.caption}
            fontSize={(theme) => theme.typography.caption}
          >
            <Box component={'span'} sx={{ fontWeight: '400' }}>
              Powered by{' '}
            </Box>
            <Box component={'span'} sx={{ fontWeight: '500' }}>
              {!!supplier.annotated_perplexity ? 'Perplexity' : 'Crunchbase'}
            </Box>
          </Box>
        </>
      </Collapse>
      {Boolean(supplier.description_ai) && (
        <Link
          color="secondary"
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'See less' : 'See more'}
          <StyledIcon
            component={KeyboardArrowDownRounded}
            color={'icon'}
            sx={{
              transform: expanded && 'rotate(-180deg)',
              transition: 'transform 300ms',
            }}
          />
        </Link>
      )}
    </Box>
  );
}

export default SupplierDetailsCompanyDetails;
