import { Box } from '@mui/material';
import { ReactComponent as AiSparkle } from '../../common/icons/aiSparkle.svg';
import InfoMessageHover from '../../common/InfoMessageHover';

const SupplierDetailsAISummary = ({ supplier, fullScreen }) => {
  const hasAISummary =
    supplier?.description_ai &&
    supplier?.description_ai.toLowerCase() !== 'unknown';
  const categories = [
    ...((Boolean(hasAISummary) && supplier?.categories_ai) || supplier?.categories || []),
  ];

  if (!supplier) return <></>;

  return (
    <>
      <Box
        sx={{
          paddingLeft: fullScreen ? '0px' : '24px',
          marginBottom: categories.length ? '6px' : '24px',
        }}
      >
        {Boolean(supplier.short_description && !hasAISummary) && (
          <Box
            fontSize={(theme) => theme.typography.body1}
            color={(theme) => theme.palette.text.caption}
          >
            {supplier.short_description}
          </Box>
        )}
        {Boolean(hasAISummary) && (
          <>
            <Box
              paddingBottom={'6px'}
              display={'flex'}
              gap={'6px'}
              alignItems={'center'}
            >
              <AiSparkle />
              <Box
                paddingLeft={'4px'}
                fontSize={(theme) => theme.typography.subtitle2}
                color={(theme) => theme.palette.secondary.main}
              >
                AI Summary
              </Box>
              <InfoMessageHover
                message={`This overview was generated with the help of AI. It’s supported by info from across the financial and procurement systems. Generative AI is a work in progress and info quality may vary.`}
              />
            </Box>
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.caption}
            >
              {supplier.description_ai}
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ paddingLeft: fullScreen ? '0px' : '24px', paddingBottom: '16px' }}>
        {Boolean(categories.length) && (
          <Box sx={{ display: 'flex', marginBottom: '2px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              {categories.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid #0288D180',
                    borderRadius: '100px',
                    padding: '4px 10px',
                    fontSize: '13px',
                    color: '#0288D1',
                  }}
                >
                  {item}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SupplierDetailsAISummary;
