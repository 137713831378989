import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const TextListWithOverflow = ({ strings }) => {
  const containerRef = useRef(null);
  const [visibleStrings, setVisibleStrings] = useState([]);
  const [remainingCount, setRemainingCount] = useState(0);

  useEffect(() => {
    const calculateVisibleStrings = () => {
      if (!containerRef.current) return;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = '16px Roboto'; // Match your font size and family

      let usedWidth = 0;
      const padding = 8; // Total padding for the item
      const gap = 8; // Space between items
      const containerMaxWidth = containerRef.current.offsetWidth;

      const visible = [];
      let remaining = 0;

      for (let i = 0; i < strings.length; i++) {
        const textWidth = context.measureText(strings[i]).width + padding;

        if (usedWidth + textWidth <= containerMaxWidth) {
          visible.push(strings[i]);
          usedWidth += textWidth + gap;
        } else {
          remaining = strings.length - i;
          break;
        }
      }

      setVisibleStrings(visible);
      setRemainingCount(remaining);
    };

    calculateVisibleStrings();

    // Recalculate on window resize
    const handleResize = () => calculateVisibleStrings();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [strings]);

  return (
    <Box
      ref={containerRef}
      style={{
        display: 'flex',
        gap: '8px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        alignItems: 'center',
      }}
    >
      {visibleStrings.map((string, index) => (
        <Box
          component={'span'}
          key={index}
          style={{
            display: 'inline-block',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '16px',
            fontWeight: '400',
          }}
        >
          {`${string}${index < visibleStrings.length - 1 ? ',' : ''}`}
        </Box>
      ))}
      {remainingCount > 0 && (
        <Box
          component={'span'}
          style={{
            display: 'inline-block',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          +{remainingCount}
        </Box>
      )}
    </Box>
  );
};

export default TextListWithOverflow;
