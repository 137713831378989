import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FullStory } from '@fullstory/browser';
import { useLocation } from "react-router-dom";
import NoResultCard from "../../common/NoResultCard";
import Notifications from "../../common/Notifications";
import { getInteractionsOverview } from "../../store/storeFunctions";
import EngagementStage from "./EngagementStage";
import InteractionsByDepartments from "./InteractionsByDepartments";
import InteractionsByMembers from "./InteractionsByMembers";
import NewInteractions from "./NewInteractions";
import Stats from "./Stats";

const Overview = ({variant}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    user, interactionsOverview, isLoadingOverview, search, page, filtersOverview, filtersInteractions, sortBy,
    sortDirection, procurementFilterData
  } = useSelector((state) => state.appSlice);

  useEffect(() => {
    if (variant === 'interactions') {
      if(!procurementFilterData?.internal_stakeholders || !user)  return;

      dispatch(
        getInteractionsOverview({
          timeframe: filtersInteractions.timeframe,
          stage: filtersInteractions.engagementStage,
          procurement_involved: filtersInteractions.procurementInvolved,
          renewals: filtersInteractions.renewals,
          deal_closed: "false",
          sort_by: sortBy,
          sort_direction: sortDirection,
          deprioritized: "false",
          procurement_members: filtersInteractions.procurementMembers,
          department_ids: filtersInteractions.procurementTeams,
          deal_value_ranges: filtersInteractions.dealValueRanges,
          supplier_onboarded: filtersInteractions.supplierOnboarded,
        })
      );
    } else if (page === "overview") {
      document.title = "PartnerElement | Dashboard";

      if(!procurementFilterData?.internal_stakeholders || !user)  return;

      dispatch(
        getInteractionsOverview({
          timeframe: filtersOverview.timeframe,
          stage: filtersOverview.engagementStage,
          procurement_involved: filtersOverview.procurementInvolved,
          renewals: filtersOverview.renewals,
          deal_closed: "false",
          sort_by: sortBy,
          sort_direction: sortDirection,
          deprioritized: "false",
          procurement_members: filtersOverview.procurementMembers,
          department_ids: filtersOverview.procurementTeams,
          deal_value_ranges: filtersOverview.dealValueRanges,
        })
      );
    }
  }, [page, procurementFilterData, user, filtersOverview, filtersInteractions, sortBy, location]);

  if (search && interactionsOverview.length === 0 && variant !== 'interactions') {
    return (
      <Box sx={{ height: "422px" }}>
        <NoResultCard />
      </Box>
    );
  }
if (variant !== 'interactions') {
  FullStory('setProperties', {
    type: 'page',
    properties: {
      pageName: 'Dashboard',
    }
  });
}

  return (
    <Stack flexDirection={"column"} gap={"24px"} paddingBottom={variant === 'interactions' ? '24px' : '0'}>
      {variant !== 'interactions' && <Notifications id={1} />}
      <Stats variant={variant} />
      {interactionsOverview.length > 0 ? (
        <>
          <EngagementStage variant={variant} />
          {procurementFilterData?.specific_emails?.length ?
            <InteractionsByMembers
              selectedMembers={filtersOverview.procurementMembers || []}
              procurementMembers={procurementFilterData.specific_emails.map(i => i.value)}
            />
            : <></>
          }
          {procurementFilterData?.departments?.length ?
            <InteractionsByDepartments
              selectedDepartments={filtersOverview.procurementTeams || []}
            />
            : <></>
          }
        </>
      ) : !isLoadingOverview && (
        <Box sx={{ paddingY: "76px" }}>
          <NoResultCard variant={"filtersOverview"} />
        </Box>
      )}

      <NewInteractions />
    </Stack>
  );
};

export default Overview;
