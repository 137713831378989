import { ChangeCircleRounded, CheckCircleRounded, VisibilityOffRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import StyledIcon from './StyledIcon';

const InteractionBadges = ({ interaction, gap, iconOnly }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={iconOnly ? 'row' : 'column'}
      alignItems={iconOnly ? 'center' : 'flex-start'}
      gap={gap || '16px'}
    >
      {interaction?.procurement_involved && (
        <Box gap={'6px'} alignItems={'center'} display={'flex'}>
          <StyledIcon
            component={CheckCircleRounded}
            color={'icon_success'}
            sx={{ fontSize: 20 }}
          />

          {!iconOnly && (
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.secondary}
            >
              Procurement engaged
            </Box>
          )}
        </Box>
      )}
      {interaction?.renewal && (
        <Box gap={'6px'} alignItems={'center'} display={'flex'}>
          <StyledIcon
            component={ChangeCircleRounded}
            color={'icon_warning'}
            sx={{ fontSize: 20 }}
          />
          {!iconOnly && (
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.secondary}
            >
              Renewal
            </Box>
          )}
        </Box>
      )}
      {interaction?.deprioritized && (
        <Box gap={'6px'} alignItems={'center'} display={'flex'}>
          <StyledIcon component={VisibilityOffRounded} color={'icon'} sx={{ fontSize: 20 }} />
          {!iconOnly && (
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.secondary}
            >
              Deprioritized
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default InteractionBadges;
