import { Box, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import NoResultCard from '../../common/NoResultCard';
import { appSlice } from '../../store/appSlice';
import SupplierAlternativeExternalItem from './SupplierAlternativeExternalItem';
import SupplierAlternativeItem from './SupplierAlternativeItem';

const formatSupplierAlternatives = (
  alternatives,
  hidden = false,
  starred = false,
) => {
  if (!alternatives.length) {
    return [];
  }
  if (hidden) {
    alternatives = alternatives.map((alternative) => ({
      ...alternative,
      hidden: true,
    }));
  }
  if (starred) {
    alternatives = alternatives.map((alternative) => ({
      ...alternative,
      starred: true,
    }));
  }
  const onboardedAlternatives = alternatives
    .filter((alternative) => alternative.active_account)
    .sort(
      (a, b) => b.total_spend - a.total_spend || a.name?.localeCompare(b.name),
    );
  const nonOnboardedAlternatives = alternatives
    .filter((alternative) => !alternative.active_account)
    .sort(
      (a, b) => b.total_spend - a.total_spend || a.name?.localeCompare(b.name),
    );

  return [...onboardedAlternatives, ...nonOnboardedAlternatives];
};

const SupplierAlternatives = ({
  supplierId,
  variant,
  handleClick,
  fullscreen,
}) => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');
  const [alternatives, setAlternatives] = useState([]);
  const [externalAlternatives, setExternalAlternatives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [externalLoading, setExternalLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    detailsNavigation: { previous },
  } = useSelector((state) => state.appSlice);

  const { setPreviousDetailsNavigation, setNextDetailsNavigation } =
    appSlice.actions;

  const updateLocation = (previous, next) => {
    dispatch(setPreviousDetailsNavigation(previous));
    dispatch(setNextDetailsNavigation(next));
  };

  const goForward = () => {
    const newPrevious = [...previous, `${location.pathname}${location.search}`];
    updateLocation(newPrevious, []);
  };

  const getAlternatives = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${supplierId}/alternatives`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    const data = await response.json();
    return data;
  };

  const getExternalAlternatives = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${supplierId}/external_alternatives`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    const data = await response.json();
    return data;
  };

  const labelAlternative = async (alternativeId, type, label) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${supplierId}/alternatives/${type}/${alternativeId}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ label }),
        credentials: 'include',
      },
    );
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    setLoading(true);
    getAlternatives()
      .then((data) => {
        const starredAlternatives = formatSupplierAlternatives(
          data?.starred_alternatives || [],
          false,
          true,
        );
        const alternatives = formatSupplierAlternatives(
          data?.alternatives || [],
        );
        const hiddenAlternatives = formatSupplierAlternatives(
          data?.hidden_alternatives || [],
          true,
        );
        setAlternatives([
          ...starredAlternatives,
          ...alternatives,
          ...hiddenAlternatives,
        ]);
        setLoading(false);
      })
      .catch(() => {
        setAlternatives([]);
        setLoading(false);
      });
    getExternalAlternatives()
      .then((data) => {
        const starredExternalAlternatives = data?.starred_alternatives || [];
        const hiddenExternalAlternatives = data?.hidden_alternatives || [];
        const externalAlternatives = data?.alternatives || [];
        setExternalAlternatives([
          ...starredExternalAlternatives,
          ...externalAlternatives,
          ...hiddenExternalAlternatives,
        ]);
        setExternalLoading(false);
      })
      .catch(() => {
        setExternalAlternatives([]);
        setExternalLoading(false);
      });
  }, [supplierId]);

  const handleStar = (alternativeId, isExternal) => {
    labelAlternative(
      alternativeId,
      isExternal ? 'external' : 'internal',
      'star',
    ).then(() => {
      setAlternatives(
        alternatives.map((alternative) =>
          alternative.internal_uuid === alternativeId
            ? { ...alternative, starred: !alternative.starred }
            : alternative,
        ),
      );
      setExternalAlternatives(
        externalAlternatives.map((alternative) =>
          alternative.id === alternativeId
            ? { ...alternative, starred: !alternative.starred }
            : alternative,
        ),
      );
    });
  };

  const handleHide = (alternativeId, isExternal) => {
    labelAlternative(
      alternativeId,
      isExternal ? 'external' : 'internal',
      'hide',
    ).then(() => {
      setAlternatives(
        alternatives.map((alternative) =>
          alternative.internal_uuid === alternativeId
            ? { ...alternative, hidden: !alternative.hidden }
            : alternative,
        ),
      );
      setExternalAlternatives(
        externalAlternatives.map((alternative) =>
          alternative.id === alternativeId
            ? { ...alternative, hidden: !alternative.hidden }
            : alternative,
        ),
      );
    });
  };

  if (loading || externalLoading) {
    return <LoadingSkeleton variant="stats" />;
  }

  if (!alternatives.length && !externalAlternatives.length) {
    return <NoResultCard variant="supplier-alternatives" />;
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'16px'}
      maxWidth={variant === 'sidebar' ? '632px' : '896px'}
    >
      {Boolean(
        alternatives.length && (fullscreen === 'internal' || !fullscreen),
      ) && (
        <>
          <Box display={'flex'} gap={'4px'} flexDirection={'column'}>
            <Box
              fontSize={(theme) => theme.typography.h6}
              color={(theme) => theme.palette.text.primary}
            >
              Internal
            </Box>
            <Box
              color={(theme) => theme.palette.text.secondary}
              fontSize={(theme) => theme.typography.body1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box
                fontSize={(theme) => theme.typography.body1}
                color={(theme) => theme.palette.text.caption}
              >
                {alternatives.length} alternatives found.
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: '16px',
            }}
          >
            {alternatives.slice(0, fullscreen ? undefined : 3).map((item) => (
              <Box width={'100%'} key={item.id} onClick={() => handleClick()}>
                <SupplierAlternativeItem
                  supplier={item}
                  sidebar={variant === 'sidebar'}
                  handleStar={handleStar}
                  handleHide={handleHide}
                />
              </Box>
            ))}
          </Box>
          {Boolean(alternatives.length > 3 && !fullscreen) && (
            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                fontSize={(theme) => theme.typography.subtitle2}
                color="secondary"
                onClick={() => {
                  goForward();
                  navigate(`?alternativeType=internal${searchQuery ? `&q=${searchQuery}` : ''}`);
                }}
              >
                See all internal alternatives
              </Link>
            </Box>
          )}
        </>
      )}
      {Boolean(
        externalAlternatives.length &&
          (fullscreen === 'external' || !fullscreen),
      ) && (
        <Box>
          <Box display={'flex'} gap={'4px'} flexDirection={'column'}>
            <Box
              fontSize={(theme) => theme.typography.h6}
              color={(theme) => theme.palette.text.primary}
            >
              External
            </Box>
            <Box
              color={(theme) => theme.palette.text.secondary}
              fontSize={(theme) => theme.typography.body1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box
                fontSize={(theme) => theme.typography.body1}
                color={(theme) => theme.palette.text.caption}
              >
                {externalAlternatives.length} alternatives found.
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: '16px',
            }}
          >
            {externalAlternatives
              .slice(0, fullscreen ? undefined : 3)
              .map((item) => (
                <SupplierAlternativeExternalItem
                  key={item.id}
                  supplier={item}
                  handleStar={handleStar}
                  handleHide={handleHide}
                />
              ))}
            {Boolean(externalAlternatives.length > 3 && !fullscreen) && (
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Link
                  sx={{ cursor: 'pointer' }}
                  underline="hover"
                  fontSize={(theme) => theme.typography.subtitle2}
                  color="secondary"
                  onClick={() => {
                    goForward();
                    navigate(`?alternativeType=external${searchQuery ? `&q=${searchQuery}` : ''}`);
                  }}
                >
                  See all external alternatives
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupplierAlternatives;
