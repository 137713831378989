import {
  Add,
  ArrowDropDown,
  Close,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import FilterChipAutocomplete from './FilterChipAutocomplete';
import StyledIcon from './StyledIcon';

export default function MultiLevelSelect({
  taxonomy,
  onChange,
  filtersSuppliers,
}) {
  const [sortedTaxonomy, setSortedTaxonomy] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);

  useEffect(() => {
    if (!filtersSuppliers || !filtersSuppliers.taxonomy_tags) return;
    setSelectedValues(filtersSuppliers.taxonomy_tags);
  }, [filtersSuppliers.taxonomy_tags]);

  useEffect(() => {
    if (search.length > 2) {
      setExpanded(
        Object.keys(taxonomy).reduce((acc, category) => {
          acc[category] = true;
          return acc;
        }, {}),
      );
    }

    if (search.length === 0) {
      setExpanded({});
    }
  }, [search]);

  useEffect(() => {
    if (!taxonomy || Object.keys(taxonomy).length === 0) return;
    const sortedCategories = Object.keys(taxonomy).sort((a, b) => a.localeCompare(b));
    const sortedTaxonomy = {};
    sortedCategories.forEach((category) => {
      sortedTaxonomy[category] = taxonomy[category];
    });
    setSortedTaxonomy(sortedTaxonomy);
  }, [taxonomy]);

  useEffect(() => {
    if (!filtersSuppliers.taxonomy_tags || !filtersSuppliers.taxonomy_tags.length) {
      setSelectedValues([]);
      return;
    }
  }, [filtersSuppliers.taxonomy_tags]);

  const handleSelect = (category, children) => {
    let newValues = [...selectedValues];

    if (children) {
      const allChildrenSelected = children.every((child) =>
        newValues.includes(child),
      );

      if (allChildrenSelected) {
        newValues = newValues.filter((val) => !children.includes(val));
      } else {
        newValues = [...new Set([...newValues, ...children])];
      }
    } else {
      if (newValues.includes(category)) {
        newValues = newValues.filter((val) => val !== category);
      } else {
        newValues.push(category);
      }
    }

    setSelectedValues(newValues);
    onChange(newValues);
  };

  const toggleExpand = (category) => {
    setExpanded((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClickOutside = (event, arrowClick = false) => {
    if (arrowClick) {
      setAnchorEl(null);
      setSearch('');
      return;
    }
    if (
      anchorEl &&
      popperRef.current &&
      !anchorEl.contains(event.target) &&
      !popperRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
      setSearch('');
    }
  };

  useEffect(() => {
    if (anchorEl) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [anchorEl]);

  const open = Boolean(anchorEl);

  const checkboxStyle = {
    '&.Mui-checked': {
      color: '#6558B1',
    },
    '&.MuiCheckbox-indeterminate': {
      color: '#6558B1',
    },
  };

  const listItemTextStyle = {
    fontSize: '14px',
    fontWeight: 400,
    padding: '0px',
  };

  if (!sortedTaxonomy || Object.keys(sortedTaxonomy).length === 0) {
    return null;
  }

  const options = Object.values(taxonomy)
    .map(({ l2_tags }) =>
      l2_tags.map((tag) => ({
        label: tag.label || tag.l2,
        value: tag.full_tag || tag.l2,
      })),
    )
    .flat();

  return (
    <>
      <FilterChipAutocomplete
        options={options}
        label="Tag"
        placeholder="Tag"
        onSelectionChange={onChange}
        procurementMembers={filtersSuppliers.taxonomy_tags}
        showInitials={false}
        restrictValues
        hideDropdown
      />
      <TextField
        variant="standard"
        placeholder="Tag"
        InputProps={{
          autoComplete: 'off',
          startAdornment: (
            <InputAdornment position="start">
              <StyledIcon component={Add} color="icon_base" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={(e) => handleClickOutside(e, true)}
                sx={{ padding: 0, rotate: anchorEl ? '180deg' : '0deg' }}
              >
                <StyledIcon component={ArrowDropDown} color="icon_base" />
              </IconButton>
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        fullWidth
        sx={{
          '& .MuiInputBase-root': {
            border: 'none',
            padding: 0,
          },
          '& .Mui-disabled': {
            color: 'text.primary',
          },
        }}
        value={search}
        onChange={handleSearchChange}
        onClick={handleClick}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 2 }}
        placement="bottom-start"
        sx={{ width: 'fit-content', minWidth: '200px', padding: 0 }}
      >
        <Paper
          elevation={2}
          ref={popperRef}
          sx={{
            width: 'fit-content',
            overflowY: 'auto',
            maxHeight: '300px',
            padding: 0,
          }}
        >
          {Object.entries(sortedTaxonomy).map(([category, data]) => {
            const allChildren = data.l2_tags.map((tag) => tag.full_tag || tag.l2);
            const isParentChecked = allChildren.every((val) =>
              selectedValues.includes(val),
            );
            const isChildChecked = data.l2_tags.some((tag) =>
              selectedValues.includes(tag.full_tag || tag.l2),
            );

            const filteredChildren = data.l2_tags.filter((tag) =>
              tag.full_tag?.toLowerCase().includes(search.toLowerCase()) || tag.l2?.toLowerCase().includes(search.toLowerCase()),
            );

            if (search && filteredChildren.length === 0) return null;

            return (
              <Box key={category} sx={{ width: 'auto' }}>
                <MenuItem
                  onClick={() => handleSelect(category, allChildren)}
                  sx={{
                    borderBottom: '1px solid #E0E0E0',
                    padding: 0,
                    paddingRight: '10px',
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={isParentChecked}
                      indeterminate={isChildChecked && !isParentChecked}
                      sx={checkboxStyle}
                    />
                    <Box sx={listItemTextStyle}>
                      {`${category} (${data.count})`}
                    </Box>
                  </Box>
                  {Boolean(data.l2_tags.length > 1) && (
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleExpand(category);
                      }}
                    >
                      {expanded[category] ? (
                        <StyledIcon component={ExpandLess} color="icon" />
                      ) : (
                        <StyledIcon component={ExpandMore} color="icon" />
                      )}
                    </Box>
                  )}
                </MenuItem>
                {Boolean(data.l2_tags.length > 1) && (
                  <Collapse
                    in={expanded[category] && data.l2_tags.length > 1}
                    sx={{ padding: 0 }}
                  >
                    {filteredChildren.map((tag) => (
                      <MenuItem
                        key={tag.full_tag || tag.l2}
                        onClick={() => handleSelect(tag.full_tag || tag.l2)}
                        sx={{ padding: 0, pl: 3, paddingRight: '10px' }}
                      >
                        <Checkbox
                          checked={selectedValues.includes(tag.full_tag || tag.l2)}
                          sx={checkboxStyle}
                        />
                        <Box sx={listItemTextStyle}>
                          {`${tag.label || tag.l2} (${tag.count})`}
                        </Box>
                      </MenuItem>
                    ))}
                  </Collapse>
                )}
              </Box>
            );
          })}
        </Paper>
      </Popper>
    </>
  );
}
