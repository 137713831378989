import {
  CloseRounded,
  ContentCopyRounded,
  ForwardToInboxRounded,
} from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ConfirmCopy from './ConfirmCopy';
import StyledIcon from './StyledIcon';
import TeamNameWithIcon from './TeamNameWithIcon';

const mapStakeholdersToDepartments = (stakeholders) => {
  const totalNumEmails = stakeholders.reduce(
    (acc, stakeholder) => acc + stakeholder.num_emails_total,
    0,
  );
  const departments = stakeholders.map((stakeholder) => stakeholder.department);
  const uniqueDepartments = [...new Set(departments)];
  return uniqueDepartments.map((department) => {
    const deptStakeholders = stakeholders.filter(
      (stakeholder) => stakeholder.department === department,
    );
    const percentEmails =
      (deptStakeholders.reduce(
        (acc, stakeholder) => acc + stakeholder.num_emails_total,
        0,
      ) /
        totalNumEmails) *
      100;
    const percentileEmailThreshold = 100 - percentEmails;
    return {
      department,
      stakeholders: deptStakeholders,
      numStakeholders: deptStakeholders.length,
      mostActiveStakeholder: deptStakeholders.reduce(
        (acc, stakeholder) =>
          acc.num_emails_total > stakeholder.num_emails_total
            ? acc
            : stakeholder,
        deptStakeholders[0],
      ),
      percentEmails,
      percentileEmails:
        percentileEmailThreshold < 10 && percentileEmailThreshold > 0
          ? `Top ${percentileEmailThreshold.toFixed(0)}%`
          : null,
    };
  });
};

const cellStyles = (width, header) => ({
  width,
  color: (theme) => theme.palette.text.main,
  fontSize: (theme) =>
    header ? theme.typography.subtitle2 : theme.typography.body2,
});

const EmployeeContactsList = ({ contacts }) => {
  const [openEngage, setOpenEngage] = useState(null);
  const [emailTemplate, setEmailTemplate] = useState('');
  const [engageText, setEngageText] = useState('');
  const [engage, setEngage] = useState([]);
  const [confirmCopy, setConfirmCopy] = useState('id + variant');
  const [internalStakeholders, setInternalStakeholders] = useState([]);

  const handleConfirmCopy = (id, variant) => {
    setConfirmCopy(`${id} ${variant}`);
    setTimeout(() => {
      setConfirmCopy('');
    }, 1000);
  };

  const getTemplates = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/templates`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const setDefaultTemplate = (templates) => {
    const defaultTemplate = templates?.find(
      (template) => template.default_template,
    );
    if (defaultTemplate) {
      setEmailTemplate(defaultTemplate.title);
      setEngageText(defaultTemplate.text);
    }
  };

  const fetchTemplates = async () => {
    const engage = await getTemplates();
    if (engage !== 'error') {
      setEngage(engage);
    }
    setDefaultTemplate(engage);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (contacts) {
      setInternalStakeholders(mapStakeholdersToDepartments(contacts));
    }
  }, [contacts]);

  return (
    <Box
      sx={{
        border: '1px solid #DEDBEC',
        borderRadius: '12px',
        marginBottom: '24px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          padding: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Employees
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Button
              onClick={(event) => setOpenEngage(event.currentTarget)}
              variant={'contained'}
              color={'secondary'}
              sx={{ borderRadius: '10px' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  gap: '8px',
                }}
              >
                <StyledIcon component={ForwardToInboxRounded} />
                <Box
                  sx={{
                    lineHeight: '24px',
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  Engage
                </Box>
              </Box>
            </Button>
            <AnimatePresence>
              {Boolean(openEngage) && (
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: '1',
                    top: '36px',
                    boxShadow:
                      '0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
                    right: '0',
                    width: '424px',
                    borderRadius: '12px',
                    background: 'white',
                    padding: '12px',
                    maxHeight: '500px',
                    overflowY: 'auto',
                    pointerEvents: 'all',
                    ul: { padding: '0' },
                    [`@media (max-height: 600px)`]: {
                      maxHeight: '300px',
                    },
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    transition={{ duration: 0.2 }}
                    className="dropmenu"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '4px',
                      }}
                    >
                      <Box
                        color={(theme) => theme.palette.text.black}
                        fontSize={(theme) => theme.typography.subtitle1}
                      >
                        Engage
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => setOpenEngage(null)}
                          sx={{ padding: 0 }}
                        >
                          <StyledIcon component={CloseRounded} color={'icon'} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      sx={{ marginBottom: '24px' }}
                      color={(theme) => theme.palette.text.caption}
                    >
                      Reach out to fellow employees to offer help with easy and
                      effective templates.
                    </Box>
                    <Box sx={{ marginBottom: '24px' }}>
                      <FormControl sx={{ width: '100%' }}>
                        <InputLabel htmlFor="emailTemplate" color={'secondary'}>
                          Email template
                        </InputLabel>
                        <Select
                          sx={{
                            borderRadius: '10px',
                          }}
                          id={'emailTemplate'}
                          label={'Email template'}
                          value={emailTemplate}
                          color={'secondary'}
                          onChange={(event) => {
                            setEmailTemplate(event.target.value);
                            const findEngageItem = engage.find(
                              (item) => item.title === event.target.value,
                            );
                            setEngageText(
                              findEngageItem ? findEngageItem.text : '',
                            );
                          }}
                        >
                          {engage.map((item) => (
                            <MenuItem value={item.title} key={item.title}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ marginBottom: '24px' }}>
                      <FormControl sx={{ width: '100%' }}>
                        <TextField
                          sx={{ fieldset: { borderRadius: '10px' } }}
                          maxRows={12}
                          minRows={5}
                          multiline={true}
                          id={'text'}
                          label={'Select a template'}
                          value={engageText}
                          color={'secondary'}
                          onChange={() => null}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Box sx={{ position: 'relative' }}>
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(engageText);
                            handleConfirmCopy('engage', '');
                          }}
                          sx={{
                            display: 'flex',
                            textTransform: 'none',
                            color: '#694FA3',
                            gap: '8px',
                            padding: '8px',
                          }}
                          variant={'text'}
                        >
                          <StyledIcon component={ContentCopyRounded} />
                          <Box sx={{ fontSize: '14px', fontWeight: '500' }}>
                            Copy
                          </Box>
                        </Button>
                        {confirmCopy === 'engage ' && (
                          <ConfirmCopy
                            message={'Template copied'}
                            positionStyles={{
                              marginTop: '-40px',
                              marginLeft: '-20px',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </motion.div>
                </Box>
              )}
            </AnimatePresence>
          </Box>
        </Box>
      </Box>
      <Box>
        {internalStakeholders.map((stakeholders) => (
          <Accordion
            elevation={0}
            key={stakeholders.department}
            sx={{
              padding: '0px',
              '&:before': {
                display: 'none',
              },
              '&.Mui-expanded': {
                margin: '0 !important',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<StyledIcon component={ExpandMore} color="icon" />}
              sx={{
                display: 'flex',
                padding: '16px',
                width: '100%',
                borderTop: '1.5px solid #DEDBEC',
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '0 !important',
                },
                '& .MuiAccordionSummary-content': {
                  alignItems: 'center',
                  margin: 0,
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: '0',
                  alignItems: 'center',
                },
              }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Box display={'flex'} gap={'8px'} paddingRight={'8px'}>
                  <TeamNameWithIcon
                    teamName={stakeholders.department || 'Unknown'}
                    large
                  />
                  {Boolean(stakeholders.percentileEmails) && (
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.caption}
                      sx={{
                        backgroundColor: '#00000014',
                        padding: '2px 4px',
                        borderRadius: '4px',
                        height: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                      }}
                    >
                      {stakeholders.percentileEmails}
                    </Box>
                  )}
                </Box>
                <Box display={'flex'} gap={'8px'} paddingRight={'16px'}>
                  <Box
                    width={'150px'}
                    color={(theme) => theme.palette.text.main}
                    fontSize={(theme) => theme.typography.body2}
                  >
                    {stakeholders.numStakeholders}{' '}
                    {stakeholders.numStakeholders === 1 ? 'person' : 'people'}{' '}
                    engaged
                  </Box>
                  <Box
                    width={'150px'}
                    color={(theme) => theme.palette.text.main}
                    fontSize={(theme) => theme.typography.body2}
                  >
                    {stakeholders.mostActiveStakeholder?.name || ''}
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  padding: '0',
                  border: '1px solid #DEDBEC',
                  borderRadius: '12px',
                }}
              >
                <Box
                  display={'flex'}
                  padding={'16px'}
                  gap={'8px'}
                  alignItems={'center'}
                >
                  <Box {...cellStyles('210px', true)}>Name</Box>
                  <Box {...cellStyles('160px', true)}>Title</Box>
                  <Box {...cellStyles('95px', true)}>Messages</Box>
                  <Box {...cellStyles('auto', true)}>Last active</Box>
                </Box>
                {stakeholders.stakeholders.map((stakeholder) => (
                  <Box
                    key={stakeholder.id}
                    display={'flex'}
                    borderTop={'1px solid #DEDBEC'}
                    padding={'16px'}
                    gap={'8px'}
                    alignItems={'center'}
                  >
                    <Box {...cellStyles('210px', false)}>
                      {stakeholder.name}
                    </Box>
                    <Box {...cellStyles('160px', false)}>
                      {stakeholder.title || ''}
                    </Box>
                    <Box {...cellStyles('95px', false)}>
                      {stakeholder.num_emails_total || 0}
                    </Box>
                    <Box {...cellStyles('auto', false)}>
                      {stakeholder.last_active_at
                        ? moment(stakeholder.last_active_at).format(
                            'MMM D, YYYY',
                          )
                        : ''}
                    </Box>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default EmployeeContactsList;
