import { ExpandMore, OpenInNew } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ClearFiltersButton from '../../common/ClearFilters';
import CustomChip from '../../common/CustomChip';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import NoResultCard from '../../common/NoResultCard';
import StyledIcon from '../../common/StyledIcon';
import { ReactComponent as ActiveContract } from '../../common/icons/activeContract.svg';
import { tooltipDescripton } from './SupplierDetailsStructure';

const uiTypeTagValues = {
  MSA: 'masterAgreement',
  SLA: 'serviceAgreement',
  AMENDMENT: 'amendment',
  GOVERNING: 'governing',
  OTHER: 'other',
};

export const contractFilters = [
  { label: 'Active contracts', value: 'active' },
  { label: 'Master agreement', value: uiTypeTagValues.MSA },
  { label: 'Service agreement', value: uiTypeTagValues.SLA },
  { label: 'Amendment', value: uiTypeTagValues.AMENDMENT },
  { label: 'Governing', value: uiTypeTagValues.GOVERNING },
  { label: 'Other', value: uiTypeTagValues.OTHER },
];

const sortOptions = {
  DATE_ASC: 'date_asc',
  DATE_DESC: 'date_desc',
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  RECOMMENDED: 'recommended',
};

const SupplierContracts = ({ supplierId, variant }) => {
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [sortDirection, setSortDirection] = useState(sortOptions.RECOMMENDED);

  const { user } = useSelector((state) => state.appSlice);

  const getContracts = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${supplierId}/contracts`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  useEffect(() => {
    getContracts().then((res) => {
      if (res !== 'error') {
        formatContracts(res);
      }
      setIsLoading(false);
    });
  }, []);

  const handleSortDirection = (sortDirection, manualContracts) => {
    const contracts = manualContracts || filteredContracts;
    switch (sortDirection) {
      case sortOptions.RECOMMENDED:
        setFilteredContracts(recommendedOrder(contracts));
        break;
      case sortOptions.DATE_ASC:
        setFilteredContracts(
          contracts.sort(function (a, b) {
            return a.agreement_sign_date.localeCompare(b.agreement_sign_date);
          }),
        );
        break;
      case sortOptions.DATE_DESC:
        setFilteredContracts(
          contracts.sort(function (a, b) {
            return b.agreement_sign_date.localeCompare(a.agreement_sign_date);
          }),
        );
        break;
      case sortOptions.NAME_ASC:
        setFilteredContracts(
          contracts.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          }),
        );
        break;
      case sortOptions.NAME_DESC:
        setFilteredContracts(
          contracts.sort(function (a, b) {
            return b.name.localeCompare(a.name);
          }),
        );
        break;
      default:
        break;
    }
    setSortDirection(sortDirection);
  };

  const recommendedOrder = (contracts) => {
    const sortedContracts = contracts.sort(
      (a, b) => -a.agreement_sign_date.localeCompare(b.agreement_sign_date),
    );
    const orderedMasterAgreements = sortedContracts.filter((contract) =>
      contract.ui_type_tags.includes(uiTypeTagValues.MSA),
    );
    const orderedServiceAgreements = sortedContracts.filter(
      (contract) =>
        !contract.ui_type_tags.includes(uiTypeTagValues.MSA) &&
        contract.ui_type_tags.includes(uiTypeTagValues.SLA),
    );
    const orderedGoverning = sortedContracts.filter(
      (contract) =>
        !contract.ui_type_tags.includes(uiTypeTagValues.MSA) &&
        !contract.ui_type_tags.includes(uiTypeTagValues.SLA) &&
        contract.ui_type_tags.includes(uiTypeTagValues.GOVERNING),
    );
    const orderedAmendments = sortedContracts.filter(
      (contract) =>
        !contract.ui_type_tags.includes(uiTypeTagValues.MSA) &&
        !contract.ui_type_tags.includes(uiTypeTagValues.SLA) &&
        !contract.ui_type_tags.includes(uiTypeTagValues.GOVERNING) &&
        contract.ui_type_tags.includes(uiTypeTagValues.AMENDMENT),
    );
    const orderedOthers = sortedContracts.filter(
      (contract) =>
        !contract.ui_type_tags.includes(uiTypeTagValues.MSA) &&
        !contract.ui_type_tags.includes(uiTypeTagValues.SLA) &&
        !contract.ui_type_tags.includes(uiTypeTagValues.GOVERNING) &&
        !contract.ui_type_tags.includes(uiTypeTagValues.AMENDMENT) &&
        contract.ui_type_tags.includes(uiTypeTagValues.OTHER),
    );

    const orderedUntagged = sortedContracts.filter(
      (contract) => !contract.ui_type_tags.length,
    );

    return orderedMasterAgreements.concat(
      orderedServiceAgreements,
      orderedAmendments,
      orderedGoverning,
      orderedOthers,
      orderedUntagged,
    );
  };

  const handleFilterUpdates = ({ value }) => {
    const newFilters = filters.includes(value)
      ? filters.filter((prevItem) => prevItem !== value)
      : [...filters, value];
    setFilters(newFilters);
    if (!newFilters.length) {
      handleSortDirection(sortDirection, contracts);
      return;
    }
    const activeFlag = newFilters.includes('active');
    const newFilteredContracts = contracts.filter((contract) => {
      if (activeFlag & !contract.isActive) return false;
      if (activeFlag && newFilters.length === 1) return true;
      return newFilters.some((type) => contract.ui_type_tags.includes(type));
    });
    handleSortDirection(sortDirection, newFilteredContracts);
  };

  const formatContracts = (contracts) => {
    const today = moment();
    const formattedContracts = contracts.map((contract) => {
      return {
        ...contract,
        isActive:
          !contract.agreement_expiration_date ||
          !moment(contract.agreement_expiration_date).isBefore(today),
      };
    });
    setContracts(formattedContracts);
    handleSortDirection(sortOptions.RECOMMENDED, formattedContracts);
  };

  const tableHeaders = [
    {
      label: 'Detected title',
      width: variant === 'sidebar' ? '200px' : '274px',
    },
    {
      label: 'Detected type',
      width: variant === 'sidebar' ? '140px' : '210px',
    },
    {
      label: 'Agreement date',
      width: variant === 'sidebar' ? '110px' : '103px',
    },
    {
      label: 'Expiry date',
      width: variant === 'sidebar' ? '0px' : '90px',
    },
    { label: '', width: variant === 'sidebar' ? '110px' : '80px' },
  ];

  const tooltipStyle = {
    tooltip: {
      sx: {
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
        background: '#FFF',
        boxShadow: '0px 3px 5px -1px #00000033',
        boxShadow: '0px 6px 10px 0px #00000024',
        boxShadow: '0px 1px 18px 0px #0000001F',
      },
    },
  };

  const handleChange = (panel) => {
    setExpanded(panel === expanded ? null : panel);
  };

  if (isLoading) {
    return <LoadingSkeleton variant="stats" />;
  }
  if (!contracts.length) {
    return <NoResultCard variant="contracts" />;
  }
  return (
    <Box maxWidth={variant === 'sidebar' ? '632px' : '896px'}>
      <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        <Box display={'flex'} gap={'8px'} flexWrap={'wrap'}>
          {contractFilters.map((item) => (
            <CustomChip
              key={item.label}
              label={item.label}
              isActive={filters.includes(item.value)}
              onClick={() => {
                handleFilterUpdates(item);
              }}
            />
          ))}
        </Box>
      </Box>
      <Box
        color={(theme) => theme.palette.text.secondary}
        fontSize={(theme) => theme.typography.body1}
        paddingTop={'16px'}
        paddingBottom={'16px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box>
          {filteredContracts.length} contracts found.{' '}
          <ClearFiltersButton
            onClick={() => {
              handleSortDirection(sortDirection, contracts);
              setFilters([]);
            }}
          ></ClearFiltersButton>
        </Box>
        <Box sx={{ width: '160px' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel htmlFor="sortedBy" color={'secondary'}>
              Sort by
            </InputLabel>
            <Select
              sx={{ borderRadius: '10px' }}
              color={'secondary'}
              size={'small'}
              id={'sortedBy'}
              label={'Sort by'}
              value={sortDirection}
              onChange={(event) => handleSortDirection(event?.target?.value)}
            >
              <MenuItem value={sortOptions.RECOMMENDED}>Recommended</MenuItem>
              <MenuItem value={sortOptions.DATE_ASC}>Earliest date</MenuItem>
              <MenuItem value={sortOptions.DATE_DESC}>Newest date</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {Boolean(filteredContracts.length) && (
        <Table
          sx={{
            borderCollapse: 'separate !important',
            border: '1px solid #DEDBEC',
            borderRadius: '12px',
            width: 'auto',
            tableLayout: 'auto',
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={
                  variant === 'sidebar'
                    ? tableHeaders.length - 1
                    : tableHeaders.length
                }
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                {tableHeaders.map(({ label, width }) => {
                  if (label === 'Expiry date' && variant === 'sidebar') return;
                  return (
                    <Box key={label} sx={{ width }}>
                      <Box
                        color={(theme) => theme.palette.text.main}
                        fontSize={(theme) => theme.typography.subtitle2}
                      >
                        {label}
                      </Box>
                    </Box>
                  );
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContracts?.map(
              ({
                id,
                agreement_sign_date,
                agreement_expiration_date,
                isActive,
                ui_type_tags,
                name,
                source_link,
                view_pdf_link,
                description_ai,
                subcategories_ai,
              }) => (
                <TableRow width={'100%'} key={id}>
                  <TableCell
                    colSpan={
                      variant === 'sidebar'
                        ? tableHeaders.length - 1
                        : tableHeaders.length
                    }
                  >
                    <Accordion
                      key={id}
                      expanded={expanded === id}
                      elevation={0}
                      sx={{
                        width: '100%',
                        padding: '0px',
                        '&:before': {
                          display: 'none',
                        },
                        '&.Mui-expanded': {
                          margin: '0 !important',
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          display: 'flex',
                          padding: 0,
                          cursor: 'default',
                          '&.Mui-expanded': {
                            margin: '0 !important',
                            minHeight: '0 !important',
                          },
                          '& .MuiAccordionSummary-content': {
                            alignItems: 'flex-start',
                            gap: '16px',
                            margin: 0,
                            cursor: 'default',
                          },
                          '& .MuiAccordionSummary-content.Mui-expanded': {
                            margin: 0,
                          },
                        }}
                      >
                        <Box width={tableHeaders[0].width}>
                          <Stack
                            display={'flex'}
                            direction={'row'}
                            gap={'4px'}
                            alignItems={'center'}
                          >
                            {view_pdf_link ? (
                              <Link
                                href={`/pdf_view/${supplierId}/${id}`}
                                color="secondary"
                                target="_blank"
                                maxWidth={`calc(${tableHeaders[0].width} - 28px)`}
                              >
                                {name || 'Unknown'}
                              </Link>
                            ) : (
                              `${name || 'Unknown'}`
                            )}
                            {isActive && (
                              <Tooltip
                                title={
                                  <Box
                                    color={(theme) => theme.palette.text.main}
                                    fontSize={(theme) => theme.typography.body2}
                                  >
                                    Active contract
                                  </Box>
                                }
                                componentsProps={tooltipStyle}
                                followCursor
                              >
                                <Box
                                  display={'flex'}
                                  direction={'row'}
                                  gap={'4px'}
                                  alignItems={'center'}
                                >
                                  <StyledIcon
                                    component={ActiveContract}
                                    color="icon_success"
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          </Stack>
                        </Box>
                        <Box width={tableHeaders[1].width}>
                          <Box display={'flex'} gap={'8px'} flexWrap={'wrap'}>
                            {ui_type_tags.map((tag) => {
                              const type = contractFilters.find(
                                (type) => type.value === tag,
                              );
                              if (!type) return;
                              return (
                                <Chip
                                  key={type.label}
                                  label={type.label}
                                  size="small"
                                  sx={{
                                    fontSize: (theme) =>
                                      theme.typography.caption,
                                    height: '28px',
                                    padding: '2px 0',
                                    color: (theme) => theme.palette.text.medium,
                                  }}
                                />
                              );
                            })}
                          </Box>
                        </Box>
                        <Box width={tableHeaders[2].width}>
                          {moment(agreement_sign_date).format('ll')}
                        </Box>
                        {variant !== 'sidebar' && (
                          <Box width={tableHeaders[3].width}>
                            {Boolean(agreement_expiration_date) &&
                              moment(agreement_expiration_date).format('ll')}
                          </Box>
                        )}
                        <Box width={tableHeaders[4].width}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={'8px'}
                            justifyContent={'flex-end'}
                          >
                            {description_ai && (
                              <IconButton
                                sx={{
                                  rotate: expanded === id ? '180deg' : '0deg',
                                }}
                                onClick={() => handleChange(id)}
                              >
                                <StyledIcon
                                  component={ExpandMore}
                                  color={'icon'}
                                />
                              </IconButton>
                            )}
                            <Tooltip
                              title={
                                <Box
                                  color={(theme) => theme.palette.text.main}
                                  fontSize={(theme) => theme.typography.body2}
                                >
                                  Contract record in Ironclad
                                </Box>
                              }
                              componentsProps={tooltipStyle}
                              followCursor
                            >
                              <IconButton
                                sx={{ marginLeft: '-8px' }}
                                type="button"
                                target="_blank"
                                href={source_link}
                              >
                                <StyledIcon
                                  component={OpenInNew}
                                  color={'icon'}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          margin: '0',
                          padding: '0',
                          borderTop: '0px',
                          paddingTop: '16px',
                        }}
                      >
                        {tooltipDescripton(
                          description_ai,
                          subcategories_ai || [],
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      )}
      <Box
        paddingTop={'8px'}
        color={(theme) => theme.palette.text.secondary}
        fontSize={(theme) => theme.typography.body2}
      >
        {user?.last_sync_at && (
          <Box>
            Last sync:{' '}
            {moment(user?.last_sync_at).format('h:mmA MMMM DD, YYYY')}
          </Box>
        )}
        {contracts.length} contract records retrieved from Ironclad.
      </Box>
    </Box>
  );
};

export default SupplierContracts;
