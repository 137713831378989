import { TaskRounded } from '@mui/icons-material';
import { Box, Chip, Link } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from './CustomCard';
import { stageVariant } from './CustomChip';
import InteractionBadges from './InteractionBadges';
import Logo from './Logo';
import StyledIcon from './StyledIcon';
import TextListWithOverflow from './TextListWithOverflow';

const InteractionItem = ({ interaction, variant }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const isDashboard = variant === 'Dashboard';
  const isDetails = variant === 'Details' || variant === 'DetailsFullscreen';
  const isVariant = isDashboard || isDetails;

  let linkUrl =
    variant === 'Details' || variant === 'DetailsFullscreen'
      ? `/details/interaction/${interaction.id}${search}`
      : `details/interaction/${interaction.id}${search}`;

  return (
    <CustomCard isHover={true} onClick={() => navigate(linkUrl)}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px',
          alignItems: 'center',
          borderBottom: '1px solid #F2F2F7',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          {!isDetails && (
            <Logo size={40} img={interaction.supplier_image_url} />
          )}
          <Box
            color={(theme) => theme.palette.text.main}
            sx={{
              lineHeight: '150%',
            }}
            fontSize={(theme) => theme.typography.h6}
          >
            {interaction.uid}
          </Box>
        </Box>
        <InteractionBadges gap={'8px'} interaction={interaction} iconOnly />
      </Box>
      <Box sx={{ minHeight: '50px', padding: '16px', display: 'flex' }}>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            width: '115px',
            paddingRight: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          {interaction.deal_closed ? (
            <Box>
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.body1}
                sx={{
                  marginBottom: '8px',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <StyledIcon
                  component={TaskRounded}
                  color={'secondary'}
                  sx={{ fontSize: '20px' }}
                />
                Deal closed
              </Box>
              <Box fontSize={(theme) => theme.typography.body1}>
                <Box
                  component={'span'}
                  color={(theme) => theme.palette.text.main}
                >
                  {moment(interaction.end_time).format('MMM DD, YYYY')}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
              <Box
                color={(theme) => theme.palette.text.primary}
                fontSize={(theme) => theme.typography.caption}
              >
                <Box
                  component={'span'}
                  sx={{
                    backgroundColor: '#00000014',
                    padding: '2px 4px',
                    borderRadius: '4px',
                  }}
                >
                  Stage
                </Box>
              </Box>
              <Box>
                <Chip
                  label={interaction.stage_name}
                  size={'small'}
                  sx={{
                    ...stageVariant(interaction.stage_name),
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        {!isVariant && (
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
            sx={{
              width: '200px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              borderLeft: '1px solid #F2F2F7',
              padding: '0 8px',
            }}
          >
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.caption}
            >
              <Box
                component={'span'}
                sx={{
                  backgroundColor: '#00000014',
                  padding: '2px 4px',
                  borderRadius: '4px',
                }}
              >
                Messages
              </Box>
            </Box>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.body1}
            >
              {interaction.num_emails} emails over {interaction.num_days} days
            </Box>
          </Box>
        )}
        {Boolean(interaction.departments_involved?.length) && (
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              borderLeft: '1px solid #F2F2F7',
              padding: '0 8px',
              flex: 1,
            }}
          >
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.caption}
            >
              <Box
                component={'span'}
                sx={{
                  backgroundColor: '#00000014',
                  padding: '2px 4px',
                  borderRadius: '4px',
                }}
              >
                Teams
              </Box>
            </Box>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.body1}
            >
              <TextListWithOverflow strings={interaction.departments_involved} />
            </Box>
          </Box>
        )}
        {Boolean(interaction?.request_tickets?.length) && (
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              borderLeft: '1px solid #F2F2F7',
              padding: '0 8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '6px',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '250px',
              }}
            >
              <Logo size={24} img={'/ZipHQ-icon.png'} />
              <Link
                underline="none"
                fontSize={(theme) => theme.typography.body2}
                sx={{
                  cursor: 'pointer',
                }}
                href={interaction.request_tickets[0].link}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
              >
                {interaction.request_tickets.length} tickets
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </CustomCard>
  );
};

export default InteractionItem;
