import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { createTheme, Switch, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { setupStore } from './store/store';

import { init as initFullStory } from '@fullstory/browser';
import { PostHogProvider } from 'posthog-js/react';

initFullStory({
  orgId: process.env.REACT_APP_FULL_STORY_ORG_ID,
  devMode: process.env.NODE_ENV === 'development',
});

const posthogOptions = {
  api_host: 'https://d3uv6ukum7ct15.cloudfront.net',
  ui_host: 'https://app.posthog.com',
  capture_pageleave: false,
};

const store = setupStore();

const theme = createTheme({
  components: {
    MuiTableBody: { defaultProps: { sx: { td: { verticalAlign: 'top' } } } },
    MuiTabs: {
      styleOverrides: { indicator: { height: '4px' } },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownRounded,
        MenuProps: { PaperProps: { sx: { borderRadius: '10px' } } },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            color: '#6558B1',
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#0000000A',
            color: '#7332FF',
            fontWeight: '500',
            '&:hover': {
              backgroundColor: '#0000000F',
            },
          },
        },
      },
    },
  },
  typography: {
    icon: { fontSize: '24px' },
    h1: { fontSize: '96px', fontWeight: '300' },
    h2: { fontSize: '60px', fontWeight: '300' },
    h3: { fontSize: '48px', fontWeight: '400' },
    h4: { fontSize: '34px', fontWeight: '400' },
    h5: { fontSize: '24px', fontWeight: '400' },
    h6: { fontSize: '20px', fontWeight: '500' },
    subtitle1: { fontSize: '16px', fontWeight: '500' },
    subtitle2: { fontSize: '14px', fontWeight: '500' },
    body1: { fontSize: '16px', fontWeight: '400' },
    body2: { fontSize: '14px', fontWeight: '400' },
    caption: { fontSize: '12px', fontWeight: '400' },
  },
  palette: {
    purple: {
      main: '#7332FF',
    },
    secondary: {
      main: '#6558B1',
      light: 'rgba(101, 88, 177, 0.1)',
    },
    info: { main: '#0288D1' },
    alert: { main: '#014361' },
    alert_warning: { main: '#663C00' },
    text: {
      main: 'rgba(0, 0, 0, 0.87)',
      medium: 'rgba(0, 0, 0, 0.60)',
      light: '#00000061',
      black: '#11012B',
      caption: '#75727C',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.60)',
    },
    error: { main: '#2F236D73', light: '#EF5350', text: '#D32F2F' },
    success: { main: '#43A047' },
    icon: { main: 'rgba(101, 88, 177, 0.45)' },
    icon_success: { main: '#4CAF50' },
    icon_warning: { main: '#D0A52E' },
    icon_neutral: { main: '#666' },
    icon_base: { main: 'rgba(0, 0, 0, 0.54)' },
    icon_error: { main: '#C62828' },
    icon_disabled: { main: '#0000001F'},
    input_error: { main: '#EF5350' },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={posthogOptions}
          >
            <App />
          </PostHogProvider>
        </ThemeProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
