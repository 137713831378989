import { maxStageInteractions, stageNames, stageNumRows } from './constants';

export const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const calculateInteractionsForStats = (item) => {
  return item.closing_stage + item.negotiation_stage + item.exploration_stage;
};

export const convertToCSV = (data, headCells) => {
  let csvString = '';

  csvString += headCells.map((cell) => cell.label).join(',') + '\r\n';

  data.forEach((row) => {
    let rowData = headCells.map((cell) => `"${row[cell.id] || ''}"`).join(',');
    csvString += rowData + '\r\n';
  });

  return csvString;
};

export const isSettingsPage = (location) => {
  return location.pathname.includes('/settings');
};

export const isHelpPage = (location) => {
  return location.pathname.includes('/help');
};

export const isSettingsOrHelpPage = (location) => {
  return isSettingsPage(location) || isHelpPage(location);
};

export const isBackofficePage = (location) => {
  return location.pathname.includes('/backoffice');
};

export const isInteractionsPage = (location) => {
  return location.pathname.includes('/interactions');
};

export const isSuppliersPage = (location) => {
  return location.pathname.includes('/suppliers');
};

export const isOutreachPage = (location) => {
  return location.pathname.includes('/outreach');
};

export const isReportsPage = (location) => {
  return location.pathname.includes('/reports');
};

export const isPDFViewer = (location) => {
  return location.pathname.includes('/pdf_view');
};

export const isProductionDomain = (org) => {
  if (['Partner Element (Sandbox)', 'Nutrien Demo'].includes(org)) return false;
  return window.location.hostname.includes('app.partnerelement');
};

export const isProcurementUser = (user) => {
  if (!user) return false;
  return user.roles.some((role) => ['procurement'].includes(role));
};

export const hasReports = (user) => {
  if (
    [
      'Samsara',
      'Samsara (Copy)',
      'Partner Element (Demo)',
      'Met Test',
      'The Met',
    ].includes(user?.organization)
  )
    return true;
  return (
    user?.backoffice && !window.location.hostname.includes('app.partnerelement')
  );
};

export const isDemoEnvironment = (user) => {
  if (!user) return false;
  if (!window.location.hostname.includes('app.partnerelement')) return true;
  return ['Samsara (Copy)', 'Partner Element (Demo)'].includes(
    user?.organization,
  );
};

export const hasRedesignedHomepage = (user) => {
  if (!user) return false;
  return [
    'Samsara',
    'Samsara (Copy)',
    'Partner Element (Demo)',
    'Met Test',
    'The Met',
  ].includes(user?.organization);
};

export const hasExecutiveSummary = (user) => {
  if (!user) return false;
  if (
    ![
      'Samsara',
      'Samsara (Copy)',
      'Partner Element (Demo)',
      'Met Test',
      'The Met',
    ].includes(user?.organization)
  )
    return false;
  return window.location.pathname === '/';
};

export const getInitials = (name) => {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .slice(0, 2);
};

export const createOverviewFilters = (
  filtersOverview,
  sortBy,
  sortDirection,
) => {
  return {
    timeframe: filtersOverview.timeframe,
    stage: filtersOverview.engagementStage,
    procurement_involved: filtersOverview.procurementInvolved,
    renewals: filtersOverview.renewals,
    deal_closed: 'false',
    sort_by: sortBy,
    sort_direction: sortDirection,
    deprioritized: 'false',
    procurement_members: filtersOverview.procurementMembers,
    department_ids: filtersOverview.procurementTeams,
  };
};

const generateStageColumns = (interactions) => {
  const columns = Array.from(
    Array(maxStageInteractions / stageNumRows),
    () => [],
  );

  function distributeItems(items) {
    items.forEach((item) => {
      for (let i = 0; i < columns.length; i++) {
        // If the column is empty or contains items of the same stage, add the item to the column
        if (
          columns[i].length < stageNumRows &&
          (columns[i].length === 0 ||
            columns[i][0].stage_name === item.stage_name)
        ) {
          columns[i].push(item);
          break;
        }
      }
    });
  }

  // Distribute one item of each stage into separate columns
  const initialItems = {};
  interactions.forEach((item) => {
    if (!initialItems[item.stage_name]) {
      initialItems[item.stage_name] = item;
      columns[Object.keys(initialItems).length - 1].push(item);
    }
  });

  // Remove the initial interactions from the items to be added
  const remainingItems = interactions.filter(
    (item) =>
      !Object.keys(initialItems).find(
        (key) => initialItems[key].id === item.id,
      ),
  );

  // Distribute any remaining interactions (up to max)
  distributeItems(remainingItems);

  return columns;
};

export const createPipelineFilterView = (interactions) => {
  interactions.sort((a, b) => {
    return +b?.end_time - +a?.end_time;
  });

  const columns = generateStageColumns(interactions);

  const explorationItems = columns
    .filter((column) => column[0]?.stage_name === stageNames[0].label)
    .reduce((acc, array) => acc.concat(array), []);
  const negotiationItems = columns
    .filter((column) => column[0]?.stage_name === stageNames[1].label)
    .reduce((acc, array) => acc.concat(array), []);
  const closingItems = columns
    .filter((column) => column[0]?.stage_name === stageNames[2].label)
    .reduce((acc, array) => acc.concat(array), []);

  return { explorationItems, negotiationItems, closingItems };
};

export const formatBatchPublishQuery = (filters) => {
  const queryFilters = {
    timeframe: filters.timeframe,
    reviewed: filters.reviewStatus.length === 1 ? filters.reviewStatus : '',
    supplier_deal:
      filters.supplierDeal.length === 3 ? '' : filters.supplierDeal,
    published: filters.published,
    organization_id: filters.organizationId,
  };

  const queryString = Object.entries(queryFilters)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map(
            (item) =>
              `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`,
          )
          .join('&');
      } else {
        return Boolean(value !== 'null' && value)
          ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          : '';
      }
    })
    .filter(Boolean)
    .join('&');
  return queryString;
};

export const supplierResponseTime = (supplierDetails) => {
  let min = Math.round(
    Math.min(
      supplierDetails.median_response_time_in_hours,
      supplierDetails.avg_response_time_in_hours,
    ),
  );
  let max = Math.round(
    Math.max(
      supplierDetails.median_response_time_in_hours,
      supplierDetails.avg_response_time_in_hours,
    ),
  );

  let timeframe_min = 'hour(s)';

  if (min >= 24) {
    min = Math.round(min / 24);
    timeframe_min = 'day(s)';
  }

  let timeframe_max = 'hour(s)';

  if (max >= 24) {
    max = Math.round(max / 24);
    timeframe_max = 'day(s)';
  }

  if (min !== max) {
    if (timeframe_min !== timeframe_max) {
      return (
        Math.round(min) +
        ' ' +
        timeframe_min +
        ' - ' +
        Math.round(max) +
        ' ' +
        timeframe_max
      );
    } else {
      return Math.round(min) + ' - ' + Math.round(max) + ' ' + timeframe_max;
    }
  } else {
    return Math.round(min) + ' ' + timeframe_min;
  }
};

export const formatCurrency = (value, round) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: round ? 0 : 2,
  }).format(value);
};

export const findInterval = (ranges, value) => {
  if (!value) {
    return formatCurrency(0);
  }

  const breakpoints = ranges.slice();
  breakpoints.sort((a, b) => a - b);

  if (value < breakpoints[0]) {
    return `< ${formatCurrency(breakpoints[0])}`;
  }

  for (let i = 0; i < breakpoints.length - 1; i++) {
    if (value >= breakpoints[i] && value < breakpoints[i + 1]) {
      return `${formatCurrency(breakpoints[i])}–${formatCurrency(
        breakpoints[i + 1],
      )}`;
    }
  }

  if (value >= breakpoints[breakpoints.length - 1]) {
    return `≥ ${formatCurrency(breakpoints[breakpoints.length - 1])}`;
  }
};
