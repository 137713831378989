import { Box } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from '../../common/CustomCard';
import Logo from '../../common/Logo';
import SupplierBadges from '../../common/SupplierBadges.js';
import TextListWithOverflow from '../../common/TextListWithOverflow.js';
import { formatCurrency } from '../../utils/functions.js';

const mostRecentInteractionTime = (supplier) => {
  return (
    supplier.interactions?.length &&
    supplier.interactions.reduce((mostRecent, interaction) => {
      const currentTime = interaction.end_time;
      if (moment(currentTime).isAfter(mostRecent)) return currentTime;
      return mostRecent;
    }, moment(supplier.interactions[0].end_time))
  );
};

export const isActiveInteraction = (supplier) => {
  return moment(mostRecentInteractionTime(supplier)).isAfter(
    moment().subtract(7, 'days'),
  );
};

const SupplierItem = ({ supplier }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const hasInteractions = Boolean(supplier.interactions.length);

  const statsDepartments = [
    ...(supplier?.financial_stats?.departments || []),
    ...(supplier?.workflow_stats?.departments || []),
  ];
  const allDepartmentsInvolved = statsDepartments?.length
    ? statsDepartments
    : supplier.interactions
        ?.map((interaction) => interaction.departments_involved)
        .flat();
  const departmentsInvolved = [...new Set(allDepartmentsInvolved)].sort(
    (a, b) => a.localeCompare(b),
  );

  const recentActivityTime =
    supplier?.financial_stats?.most_recent_time ||
    supplier?.workflow_stats?.most_recent_time ||
    (hasInteractions ? mostRecentInteractionTime(supplier) : null);

  const detailsLink = `details/supplier/${supplier.id}${search}`;

  return (
    <CustomCard isHover={true} onClick={() => navigate(detailsLink)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '10px',
          borderBottom: '1px solid #F2F2F7',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <Logo size={54} img={supplier.image_url} />
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.h6}
            >
              {supplier.name}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <SupplierBadges supplier={supplier} gap={'8px'} iconOnly />
          </Box>
        </Box>
      </Box>
      {Boolean(supplier.categories || supplier.categories_ai) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            gap: '10px',
            borderBottom: '1px solid #F2F2F7',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '10px',
              maxHeight: '60px',
              overflow: 'hidden',
            }}
          >
            {[...(supplier.categories_ai || supplier.categories || [])]
              .slice(0, 5)
              .map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid #A7A4E0',
                    borderRadius: '100px',
                    padding: '4px 10px',
                    fontSize: '13px',
                    color: '#6558B1',
                  }}
                >
                  {item}
                </Box>
              ))}
          </Box>
        </Box>
      )}
      <Box sx={{ minHeight: '50px', padding: '16px', display: 'flex' }}>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            width: '143px',
            paddingRight: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.primary}
            fontSize={(theme) => theme.typography.caption}
          >
            <Box
              component={'span'}
              sx={{
                backgroundColor: '#00000014',
                padding: '2px 4px',
                borderRadius: '4px',
              }}
            >
              Last 12 month spend
            </Box>
          </Box>
          <Box
            color={(theme) => theme.palette.text.primary}
            fontSize={(theme) => theme.typography.body1}
          >
            {formatCurrency(supplier.total_spend, true)}
          </Box>
        </Box>
        {recentActivityTime && (
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
            sx={{
              width: '103px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              borderLeft: '1px solid #F2F2F7',
              padding: '0 8px',
            }}
          >
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.caption}
            >
              <Box
                component={'span'}
                sx={{
                  backgroundColor: '#00000014',
                  padding: '2px 4px',
                  borderRadius: '4px',
                }}
              >
                Last active
              </Box>
            </Box>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.body1}
            >
              {moment(recentActivityTime).format('MMM DD, YYYY')}
            </Box>
          </Box>
        )}
        {Boolean(departmentsInvolved?.length) && (
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              borderLeft: '1px solid #F2F2F7',
              padding: '0 8px',
              flex: 1,
            }}
          >
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.caption}
            >
              <Box
                component={'span'}
                sx={{
                  backgroundColor: '#00000014',
                  padding: '2px 4px',
                  borderRadius: '4px',
                }}
              >
                Teams
              </Box>
            </Box>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.body1}
            >
              <TextListWithOverflow strings={departmentsInvolved} />
            </Box>
          </Box>
        )}
      </Box>
    </CustomCard>
  );
};

export default SupplierItem;
