import { ArrowForwardRounded } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomCard from '../../common/CustomCard';
import InfoMessageHover from '../../common/InfoMessageHover';
import SimpleLineGraph from '../../common/SimpleLineGraph';
import StyledIcon from '../../common/StyledIcon';
import { encodeQuery, generateTeamFilters } from '../../store/storeFunctions';
import { defaultFiltersSuppliers } from '../../utils/constants';

const DashboardSuppliers = ({ supplierStats, filters, filterValues, handleClick }) => {
  const [onboardedSuppliers, setOnboardedSuppliers] = useState();
  const [totalSuppliers, setTotalSuppliers] = useState();
  const [totalAccounts, setTotalAccounts] = useState();
  const [onboardedSuppliersLoading, setOnboardedSuppliersLoading] =
    useState(true);
  const [totalSuppliersLoading, setTotalSuppliersLoading] = useState(true);

  const { user } = useSelector((state) => state.appSlice);

  useEffect(() => {
    setOnboardedSuppliersLoading(true);
    const timeframeInDays =
      filters.timeframe === 'custom'
        ? 'custom'
        : filters.timeframe === 12
        ? 365
        : filters.timeframe * 30;
    getSuppliers({
      onboarding_timeframe: timeframeInDays,
      start_date: moment(filters.start_date).format('MM/DD/YYYY'),
      end_date: moment(filters.end_date).format('MM/DD/YYYY'),
    }).then((res) => {
      if (res !== 'error') setOnboardedSuppliers(res?.meta?.total_count || 0);
      setOnboardedSuppliersLoading(false);
    });
  }, [filters.timeframe, filters.team]);

  useEffect(() => {
    setTotalSuppliersLoading(true);
    getSuppliers({ onboarding_timeframe: 'alltime' }).then((res) => {
      if (res !== 'error') {
        setTotalSuppliers(res?.meta?.total_count || 0);
        setTotalAccounts(res?.meta?.total_active_accounts || 0);
      }
      setTotalSuppliersLoading(false);
    });
  }, [filters.team]);

  const getSuppliers = async (customFilters) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/suppliers`;
    const queryString = encodeQuery({
      ...defaultFiltersSuppliers,
      ...customFilters,
      department_ids: generateTeamFilters(user, filterValues?.team || [], filters.team),
      active_account: 'true',
    });
    const urlWithQuery = `${apiUrl}?${queryString}&paginate=true`;
    const response = await fetch(urlWithQuery, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const handleViewAllClick = () => {
    handleClick(true, true);
  };

  if (onboardedSuppliersLoading || totalSuppliersLoading) return <></>;

  return (
    <CustomCard height={'320px'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        height={'100%'}
        width={'100%'}
      >
        <Box padding={'16px'}>
          <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Suppliers
              </Box>
              <InfoMessageHover
                positionVariant={'bottom-right'}
                message={`Partner Element merges supplier accounts across systems based on matching domains, tax IDs, or other company identifiers. This may result in different supplier counts than shown in your other systems.`}
              />
            </Box>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'16px'}>
              <CustomCard isHover onClick={handleClick}>
                <Box
                  display={'flex'}
                  padding={'16px'}
                  alignItems={'flex-end'}
                  justifyContent={'space-between'}
                >
                  <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body1}
                    >
                      Newly onboarded
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={'34px'}
                      fontWeight={'500'}
                    >
                      {onboardedSuppliers || 0}
                    </Box>
                  </Box>
                  <Box>
                    <IconButton type="button" sx={{ padding: '8px' }}>
                      <StyledIcon
                        component={ArrowForwardRounded}
                        color={'icon'}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </CustomCard>
              <CustomCard isHover onClick={handleViewAllClick}>
                <Box
                  display={'flex'}
                  padding={'16px'}
                  alignItems={'flex-end'}
                  justifyContent={'space-between'}
                >
                  <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body1}
                    >
                      Active
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={'34px'}
                      fontWeight={'500'}
                    >
                      {totalSuppliers || 0}
                    </Box>
                  </Box>
                  <Box>
                    <IconButton type="button" sx={{ padding: '8px' }}>
                      <StyledIcon
                        component={ArrowForwardRounded}
                        color={'icon'}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  padding={'16px'}
                  marginTop={'-28px'}
                  color={(theme) => theme.palette.text.secondary}
                  fontSize={(theme) => theme.typography.body2}
                >
                  {totalAccounts
                    ? `out of ${totalAccounts} Netsuite accounts`
                    : ''}
                </Box>
              </CustomCard>
            </Box>
            <Box width={'99%'} boxSizing={'border-box'} position={'relative'}>
              <SimpleLineGraph
                data={supplierStats?.summary || []}
                valueKey="date"
                getColor={() => {
                  return '#4CAF50';
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};

export default DashboardSuppliers;
