import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ListSummary from '../../common/ListSummary';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import NoResultCard from '../../common/NoResultCard';
import { appSlice } from '../../store/appSlice';
import { getSuppliers } from '../../store/storeFunctions';
import { hasRedesignedHomepage } from '../../utils/functions';
import SupplierItem from './SupplierItem';

const Suppliers = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isNewCollection, setIsNewCollection] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const {
    suppliers,
    filtersSuppliers,
    supplierPagination,
    isLoadingSuppliers,
    user,
    procurementFilterData,
  } = useSelector((state) => state.appSlice);

  const selectedSort = `${filtersSuppliers.sort_direction}`;

  const handleSortChange = (event) => {
    const sortDirection = event.target.value;

    dispatch(
      appSlice.actions.setFiltersSuppliers({
        ...filtersSuppliers,
        sort_direction: sortDirection,
      }),
    );
  };

  useEffect(() => {
    document.title = 'PartnerElement | Suppliers';

    if (searchParams.get('q')) handleSortChange({ target: { value: 'rank' } });
    if (!searchParams.get('q') && selectedSort === 'rank')
      handleSortChange({
        target: { value: hasRedesignedHomepage(user) ? 'spend-desc' : 'asc' },
      });
  }, []);

  useEffect(() => {
    if (!procurementFilterData?.internal_stakeholders || !user) return;

    setIsNewCollection(true);
    setLoadMore(false);
    dispatch(
      getSuppliers({
        ...filtersSuppliers,
        search_query: searchParams.get('q'),
        active_account: hasRedesignedHomepage(user)
          ? filtersSuppliers.active_account
          : 'false',
      }),
    );
  }, [filtersSuppliers, user, procurementFilterData, searchParams]);

  const observerRef = useRef();

  useEffect(() => {
    if (isLoadingSuppliers || !supplierPagination?.has_more) return;
    const targetElement = observerRef.current;
    if (!targetElement) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries.length && entries[0].isIntersecting && !loadMore)
        setLoadMore(true);
    });

    observer.observe(targetElement);

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [isLoadingSuppliers, observerRef.current]);

  useEffect(() => {
    if (!loadMore) return;

    setLoadMore(false);
    if (!supplierPagination?.has_more || isLoadingSuppliers) return;

    if (!procurementFilterData?.internal_stakeholders || !user) return;

    setIsNewCollection(false);
    dispatch(
      getSuppliers({
        ...filtersSuppliers,
        active_account: hasRedesignedHomepage(user)
        ? filtersSuppliers.active_account
        : 'false',
        page: supplierPagination.next_page,
      }),
    );
  }, [loadMore, procurementFilterData, user]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Box>
          {(!isLoadingSuppliers || !isNewCollection) && (
            <ListSummary
              type="suppliers"
              count={supplierPagination?.total_count}
            />
          )}
        </Box>

        <Box sx={{ width: '200px' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel htmlFor="sortedBy" color={'secondary'}>
              Sort by
            </InputLabel>
            <Select
              sx={{ borderRadius: '10px' }}
              color={'secondary'}
              size={'small'}
              id={'sortedBy'}
              label={'Sort by'}
              value={selectedSort}
              onChange={handleSortChange}
            >
              {Boolean(searchParams.get('q')) && (
                <MenuItem value={'rank'}>Recommended</MenuItem>
              )}
              <MenuItem value={'spend-desc'}>Spend: High to Low</MenuItem>
              <MenuItem value={'spend-asc'}>Spend: Low to High</MenuItem>
              <MenuItem value={'asc'}>Supplier: A-Z</MenuItem>
              <MenuItem value={'desc'}>Supplier: Z-A</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {isLoadingSuppliers && isNewCollection ? (
          [0, 1, 2, 3].map((_, index) => (
            <LoadingSkeleton variant={'interactionItem'} key={index} />
          ))
        ) : suppliers?.length === 0 ? (
          <Box sx={{ height: '422px' }}>
            <NoResultCard variant={'filtersSuppliers'} />
          </Box>
        ) : (
          <>
            {suppliers.map((supplier) => (
              <SupplierItem key={supplier.id} supplier={supplier} />
            ))}
            {supplierPagination?.has_more && !isLoadingSuppliers && (
              <div ref={observerRef}></div>
            )}
            {isLoadingSuppliers && (
              <LoadingSkeleton variant={'interactionItem'} />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Suppliers;
