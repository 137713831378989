import {
  AccessTimeFilled,
  TaskRounded,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { formatCurrency } from '../utils/functions';
import CustomCard from './CustomCard';
import { ReactComponent as CancelledContract } from './icons/cancelContract.svg';
import Logo from './Logo';
import StyledIcon from './StyledIcon';

const ActivityItem = ({ activity }) => {
  const statusIcon = (status) => {
    if (status === 'approved')
      return <StyledIcon component={TaskRounded} color={'icon_success'} />;
    if (status === 'pending')
      return <StyledIcon component={AccessTimeFilled} color={'icon_warning'} />;
    if (status === 'rejected' || status === 'cancelled')
      return <StyledIcon component={CancelledContract} color={'#ACA39A'} />;
    return <></>;
  };

  const statusText = (status) => {
    if (status === 'approved') return <Box>Approved</Box>;
    if (status === 'pending') return <Box>In progress</Box>;
    if (status === 'rejected' || status === 'cancelled') return <Box>Cancelled</Box>;
    return <></>;
  };

  return (
    <CustomCard
      isHover={true}
      onClick={() => window.open(activity.request_link, '_blank')}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px',
          alignItems: 'center',
          borderBottom: '1px solid #F2F2F7',
          height: '54px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Logo size={24} img={'/ZipHQ-icon.png'} />
          <Box
            color={(theme) => theme.palette.secondary.main}
            fontSize={(theme) => theme.typography.h6}
          >
            {activity.label}
          </Box>
        </Box>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            marginBottom: '8px',
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          {statusIcon(activity.status)} {statusText(activity.status)}
        </Box>
      </Box>
      <Box sx={{ minHeight: '50px', padding: '16px', display: 'flex' }}>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            paddingRight: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.caption}
              sx={{
                backgroundColor: '#00000014',
                borderRadius: '4px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 2px',
              }}
            >
              Amount
            </Box>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.body1}
            >
              {formatCurrency(activity.price_usd, true)}
            </Box>
          </Box>
          <Box display={'flex'} gap={'8px'} alignItems={'center'} >
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.caption}
              sx={{
                backgroundColor: '#00000014',
                borderRadius: '4px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 2px',
              }}
            >
              Start date
            </Box>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.body1}
            >
              {moment(activity.started_at).format('MMM DD, YYYY')}
            </Box>
          </Box>
        </Box>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            paddingRight: '8px',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: '1px solid #F2F2F7',
            paddingLeft: '8px',
            gap: '8px',
          }}
        >
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.caption}
              sx={{
                backgroundColor: '#00000014',
                borderRadius: '4px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 2px',
              }}
            >
              Team
            </Box>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.body1}
            >
              {activity.department}
            </Box>
          </Box>
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.caption}
              sx={{
                backgroundColor: '#00000014',
                borderRadius: '4px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 2px',
              }}
            >
              Initiated by
            </Box>
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.body1}
            >
              {activity.requester_name}
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};

export default ActivityItem;
