import { ApartmentRounded, Diversity1Outlined } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearFiltersButton from '../../common/ClearFilters';
import FilterChipAutocomplete from '../../common/FilterChipAutocomplete';
import FilterFooter from '../../common/FilterFooter';
import MultiLevelSelect from '../../common/MultiLevelSelect';
import StyledDivider from '../../common/StyledDivider';
import StyledIcon from '../../common/StyledIcon';
import { ReactComponent as ActiveContract } from '../../common/icons/activeContract.svg';
import { ReactComponent as ActiveInteraction } from '../../common/icons/activeInteraction.svg';
import { ReactComponent as OnboardedSupplier } from '../../common/icons/onboardedSupplier.svg';
import { ReactComponent as Sparkles } from '../../common/icons/sparkles.svg';
import { ReactComponent as Teams } from '../../common/icons/teams.svg';
import { appSlice } from '../../store/appSlice';
import { diversityLabels } from '../../utils/constants';
import { hasRedesignedHomepage } from '../../utils/functions';

const SupplierFilters = () => {
  const dispatch = useDispatch();
  const [industries, setIndustries] = useState([]);
  const [taxonomy, setTaxonomy] = useState(null);

  const {
    filtersSuppliers,
    supplierIndustries,
    supplierTaxonomy,
    procurementFilterData,
    user,
  } = useSelector((state) => state.appSlice);
  const { setFiltersSuppliers } = appSlice.actions;

  useEffect(() => {
    const sortedIndustries = [...supplierIndustries].sort((a, b) =>
      a.localeCompare(b),
    );
    setIndustries(
      sortedIndustries.map((industry) => ({
        label: industry,
        value: industry,
      })),
    );
  }, [supplierIndustries]);

  useEffect(() => {
    setTaxonomy(supplierTaxonomy);
  }, [supplierTaxonomy]);

  const handleFilters = (newFilters) => {
    dispatch(setFiltersSuppliers(newFilters));
  };

  const handleIndustriesChange = (newValue) => {
    handleFilters({
      ...filtersSuppliers,
      industries: newValue.map((item) => (item?.value ? item.value : item)),
    });
  };

  const handleTaxonomyChange = (newValue) => {
    handleFilters({
      ...filtersSuppliers,
      taxonomy_tags: newValue.map((item) => (item?.value ? item.value : item)),
    });
  };

  const handleDiversitySpotlightsChange = (newValue) => {
    handleFilters({
      ...filtersSuppliers,
      diversity_spotlights: newValue.map((item) =>
        item?.value ? item.value : item,
      ),
    });
  };

  const handleProcurementTeamsChange = (newValue) => {
    handleFilters({
      ...filtersSuppliers,
      department_ids: newValue.map((item) => (item?.value ? item.value : item)),
    });
  };

  return (
    <Box
      sx={{
        maxHeight: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '42px',
        gap: '24px',
        border: '1px solid #DEDBEC',
        borderRadius: '12px',
        margin: '24px 0 32px 24px',
        padding: '16px',
        background: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          paddingBottom: '24px',
          borderBottom: '1px solid #79747E29',
        }}
      >
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.h6}
        >
          Filters
        </Box>
        {Boolean(
          procurementFilterData?.departments &&
            procurementFilterData?.departments.length,
        ) && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  gap: '8px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'8px'}
                >
                  Teams
                </Box>
                <Box>
                  <Teams />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  <FilterChipAutocomplete
                    options={
                      hasRedesignedHomepage(user)
                        ? procurementFilterData?.cost_centers
                        : procurementFilterData?.departments
                    }
                    label="Team"
                    placeholder="Team"
                    onSelectionChange={handleProcurementTeamsChange}
                    procurementMembers={filtersSuppliers.department_ids}
                    showInitials={false}
                    restrictValues
                  />
                </Box>
              </Box>
            </Box>
            <StyledDivider />
          </>
        )}
        {Boolean(taxonomy && Object.keys(taxonomy).length) && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  paddingBottom: '6px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'6px'}
                >
                  Spend tags
                </Box>
                <Box>
                  <Sparkles />
                </Box>
              </Box>
              <Box>
                <MultiLevelSelect
                  taxonomy={taxonomy}
                  onChange={handleTaxonomyChange}
                  filtersSuppliers={filtersSuppliers}
                />
              </Box>
            </Box>
            <StyledDivider />
          </>
        )}
        {!Boolean(taxonomy && Object.keys(taxonomy).length) && Boolean(industries && industries.length) && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  gap: '8px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'8px'}
                >
                  Industries
                </Box>
                <StyledIcon component={ApartmentRounded} color="icon" />
              </Box>
              <Box>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  <FilterChipAutocomplete
                    options={industries}
                    label="Industries"
                    placeholder="Industries"
                    onSelectionChange={handleIndustriesChange}
                    procurementMembers={filtersSuppliers.industries}
                    showInitials={false}
                    restrictValues
                  />
                </Box>
              </Box>
            </Box>
            <StyledDivider />
          </>
        )}
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              gap: '8px',
            }}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
              paddingBottom={'8px'}
            >
              Diversity spotlight
            </Box>
            <StyledIcon component={Diversity1Outlined} color="icon" />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              <FilterChipAutocomplete
                options={diversityLabels}
                label="Diversity spotlight"
                placeholder="Diversity spotlight"
                onSelectionChange={handleDiversitySpotlightsChange}
                procurementMembers={filtersSuppliers.diversity_spotlights}
                showInitials={false}
                restrictValues
              />
            </Box>
          </Box>
        </Box>
        <StyledDivider />
        <Box>
          <Stack
            marginBottom={'2px'}
            direction={'row'}
            spacing={'6px'}
            alignItems={'center'}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              Active in last 7 days
            </Box>
            <StyledIcon component={ActiveInteraction} color="icon_success" />
          </Stack>
          <Box>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={filtersSuppliers.is_active || null}
                onChange={(event) =>
                  handleFilters({
                    ...filtersSuppliers,
                    is_active: event.target.value,
                  })
                }
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'secondary'} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'secondary'} />}
                  label="No"
                />{' '}
                <FormControlLabel
                  value={'null'}
                  control={<Radio color={'secondary'} />}
                  label={'Both'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <StyledDivider />
        <Box>
          <Stack
            marginBottom={'2px'}
            direction={'row'}
            spacing={'6px'}
            alignItems={'center'}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              Active contract
            </Box>
            <StyledIcon component={ActiveContract} color="icon_success" />
          </Stack>
          <Box>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={filtersSuppliers.is_active_contract || 'null'}
                onChange={(event) =>
                  handleFilters({
                    ...filtersSuppliers,
                    is_active_contract: event.target.value,
                  })
                }
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'secondary'} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'secondary'} />}
                  label="No"
                />{' '}
                <FormControlLabel
                  value={'null'}
                  control={<Radio color={'secondary'} />}
                  label={'Both'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel htmlFor="contract_timeframe" color={'secondary'}>
              Include suppliers with contracts ending in
            </InputLabel>
            <Select
              sx={{ borderRadius: '10px' }}
              id={'contract_timeframe'}
              color={'secondary'}
              label={'Include suppliers with contracts ending in'}
              value={filtersSuppliers.expiring_contract_timeframe}
              onChange={(event) =>
                handleFilters({
                  ...filtersSuppliers,
                  expiring_contract_timeframe: event.target.value,
                })
              }
            >
              <MenuItem value={30}>Next 30 days</MenuItem>
              <MenuItem value={60}>Next 60 days</MenuItem>
              <MenuItem value={90}>Next 90 days</MenuItem>
              <MenuItem value={180}>Next 180 days</MenuItem>
              <MenuItem value={'alltime'}>All time</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {hasRedesignedHomepage(user) && (
          <>
            <StyledDivider />
            <Box>
              <Stack
                marginBottom={'2px'}
                direction={'row'}
                spacing={'6px'}
                alignItems={'center'}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                >
                  Onboarded supplier
                </Box>
                <StyledIcon component={OnboardedSupplier} />
              </Stack>
              <Box>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={filtersSuppliers.active_account || 'null'}
                    onChange={(event) =>
                      handleFilters({
                        ...filtersSuppliers,
                        active_account: event.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value={'true'}
                      control={<Radio color={'secondary'} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={'false'}
                      control={<Radio color={'secondary'} />}
                      label="No"
                    />{' '}
                    <FormControlLabel
                      value={'null'}
                      control={<Radio color={'secondary'} />}
                      label={'Both'}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </>
        )}
        {hasRedesignedHomepage(user) && (
          <>
            <Box>
              <FormControl sx={{ width: '100%' }} size="small">
                <InputLabel htmlFor="timeframe" color={'secondary'}>
                  Include suppliers onboarded within
                </InputLabel>
                <Select
                  sx={{ borderRadius: '10px' }}
                  id={'timeframe'}
                  color={'secondary'}
                  label={'Include suppliers onboarded within'}
                  value={filtersSuppliers.onboarding_timeframe}
                  onChange={(event) =>
                    handleFilters({
                      ...filtersSuppliers,
                      onboarding_timeframe: event.target.value,
                    })
                  }
                >
                  <MenuItem value={7}>Last 7 days</MenuItem>
                  <MenuItem value={30}>Last 30 days</MenuItem>
                  <MenuItem value={60}>Last 60 days</MenuItem>
                  <MenuItem value={90}>Last 90 days</MenuItem>
                  <MenuItem value={180}>Last 180 days</MenuItem>
                  <MenuItem value={365}>Last 365 days</MenuItem>
                  <MenuItem value={1800}>Last 5 years</MenuItem>
                  <MenuItem value={'alltime'}>All time</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </>
        )}

        <StyledDivider />
        <Box>
          <Stack
            marginBottom={'2px'}
            direction={'row'}
            spacing={'6px'}
            alignItems={'center'}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              Has internal alternatives
            </Box>
          </Stack>
          <Box>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={filtersSuppliers.has_alternatives || null}
                onChange={(event) =>
                  handleFilters({
                    ...filtersSuppliers,
                    has_alternatives: event.target.value,
                  })
                }
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'secondary'} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'secondary'} />}
                  label="No"
                />{' '}
                <FormControlLabel
                  value={'null'}
                  control={<Radio color={'secondary'} />}
                  label={'Both'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <StyledDivider />
        <Box>
          <ClearFiltersButton
            type={'big'}
            variant={'filtersSuppliers'}
          ></ClearFiltersButton>
        </Box>
      </Box>
      <FilterFooter />
    </Box>
  );
};

export default SupplierFilters;
