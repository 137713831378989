import { Box, Chip, Table, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdobePDFEmbedClient from '../../common/AdobePDFEmbedClient';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import { contractFilters } from './SupplierContracts';

const ContractViewer = () => {
  const { supplierId, contractId } = useParams();
  const [contract, setContract] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getContract().then((res) => {
      if (res !== 'error') {
        setContract(res);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!contract || !contract.view_pdf_link) return;
    const viewerConfig = {
      divId: 'pdf-div',
    };

    const pdfConfig = {
      content: {
        location: {
          url: contract.view_pdf_link,
        },
      },
      metaData: {
        fileName: `${contract.name}`,
        hasReadOnlyAccess: true,
      },
    };
    const viewSDKClient = new AdobePDFEmbedClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      viewSDKClient.previewFile('pdf-div', viewerConfig, pdfConfig);
    });
  }, [contract]);

  const getContract = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${supplierId}/contracts/${contractId}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  if (isLoading || !contract) {
    return (
      <Table>
        <TableBody>
          <LoadingSkeleton variant={'adminLogs'} />
        </TableBody>
      </Table>
    );
  }
  return (
    <Box>
      <Box padding={'16px 26px'} borderBottom={'1.5px solid #6558B11A'}>
        <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          <Box
            color={(theme) => theme.palette.text.black}
            fontSize={(theme) => theme.typography.h6}
          >
            {contract.name}
          </Box>
          <Box display={'flex'} gap={'10px'}>
            {contract.ui_type_tags.map((tag) => {
              const type = contractFilters.find((type) => type.value === tag);
              if (!type) return;
              return (
                <Chip
                  key={type.label}
                  label={type.label}
                  size="small"
                  sx={{
                    fontSize: (theme) => theme.typography.caption,
                    height: '28px',
                    padding: '2px 0',
                    color: (theme) => theme.palette.text.medium,
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box width={'100%'} height={'calc(100vh - 100px)'} id="pdf-div"></Box>
    </Box>
  );
};

export default ContractViewer;
